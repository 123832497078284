function createEndpoint(template) {
  return function (params, query) {
    const url = Object.keys(params || {}).reduce((_url, key) => {
      const value = params[key];
      return _url.replaceAll(`:${key}`, value);
    }, template);

    const searchParams = new URLSearchParams(query || {});
    return `${url}?${searchParams.toString()}`;
  };
}

export const endpoints = {
  viewers: {
    findOne: createEndpoint("/viewers/:id"),
    getAll: createEndpoint("/viewers"),
    authenticate: createEndpoint("/viewers/authenticate"),
  },
  modules: {
    findOne: createEndpoint("/user-modules/:id"),
    getAll: createEndpoint("/viewer-modules"),
    authenticate: createEndpoint("/viewer-modules/authenticate"),

  },
};
