/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";
import { LinearProgress } from "@mui/material";
import axios from "axios";
import { baseUrl } from "api";
import ModuleBase from "components/ModuleBase";
import NoModule from "components/NoModule";
import { ModuleId } from "utils/getModuleId";
import { useViewer } from "viewer";

function VirtualTour() {
  const [viewer_, setViewer] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const { sideBarRoutes } = useViewer();
  const url = window.location.href;
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${baseUrl}/viewer-modules/${ModuleId(sideBarRoutes)}?`)
      .then((res) => {
        setIsLoading(false);
        setViewer(res.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [sideBarRoutes, url]);

  return (
    <ModuleBase
      component={
        isLoading ? (
          <>
            {" "}
            <center>{/* <LinearProgress color="secondary" />{" "} */}</center>
          </>
        ) : viewer_.virtualTourUrl ? (
          <Iframe
            url={viewer_.virtualTourUrl}
            width="100%"
            height="100%"
            id="myId"
            // className=""

            // display="initial"
            position="relative"
          />
        ) : (
          <NoModule text="No tour at the moment" />
        )
      }
    />
  );
}
export default VirtualTour;
