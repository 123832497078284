/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { baseUrl } from "api";
import axios from "axios";
import checkImageHeight from "utils/checkImageHeight";
import NoModule from "components/NoModule";
import { CircularProgress } from "@mui/material";

const StandardPhotos = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const moduleName = props.moduleType === "floor-plans" ? "floor-plans" : "photo-gallery";

  useEffect(() => {
    setIsLoading(true);

    axios
      .get(`${baseUrl}/${moduleName}-folders/${props?.moduleId}`)
      .then(async (res) => {
        const files = await Promise.all(
          res?.data?.files?.map(async (file) => {
            const isPortrait = await checkImageHeight(file);
            return {
              type: "File",
              url: file,
              thumb: file,
              isPortrait,
            };
          })
        );
        props.setFiles(files);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [props.moduleId]);

  let standerType = localStorage.getItem("folderName") || false;
  let filterData = props.files;
  if (standerType != false) {
    filterData = props.files.filter((value) => {
      return value.type == standerType;
    });
  }

  function handleFoldeFile(folderName, url, isPortrait = false) {
    props.control("single", [1, 1, 0.5]);
    localStorage.setItem("folderName", folderName);
    localStorage.setItem("url", url);
    isPortrait
      ? localStorage.setItem("isPortrait", true)
      : localStorage.removeItem("isPortrait") || false;
  }

  function handleFile(folderName, url, itemIndex) {
    props.control("single", [1, 1, 0.5]);
    localStorage.setItem("folderName", folderName);
    localStorage.setItem("url", url);
    localStorage.setItem("itemIndex", itemIndex);
  }

  const gridImageStyle = {
    borderRadius: props.viewer_?.useRoundedCorners ? "0.63rem" : "0", // Set border radius to 0.75rem if useRoundedCorners is true, otherwise, set it to 0.
    border: props.viewer_?.useLogoBox ? `3px solid ${props.viewer_?.colorOfBox || "white"}` : "", // Set the border color to props.viewer_?.colorOfBox if it exists, otherwise, set it to white.
  };

  const galleryLayoutStanderImageImgStyle = {
    width: "100%",
    maxWidth: "100%",
    height: "100%",
    borderRadius: props.viewer_?.useRoundedCorners ? "6px" : "0",
  };

  return (
    <>
      {isLoading ? (
        <div className="h-full w-full rounded-xl sm:overflow-hidden flex flex-col 2xl:justify-center items-center space-y-5 2xl:space-y-10 overflow-y-scroll comingsoontext">
          <>
            <div className="text-center">
              <CircularProgress
                style={{
                  color: props.viewer_?.windowColor ? `#${props.viewer_?.windowColor}` : "white",
                }}
              />
            </div>
          </>
        </div>
      ) : !isLoading && props.files.length === 0 ? (
        <>
          <NoModule text="No photos at the moment" />
        </>
      ) : (
        <Grid style={{ position: "relative" }} container>
          {props?.files?.map((item, index) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                lg={3}
                xl={2}
                className="centerItemFlex"
                style={{ marginBottom: "32px" }}
              >
                {item["isPortrait"] ? (
                  <div
                    onClick={() => handleFoldeFile(item.type, item.url, item.isPortrait)}
                    class="shadow-bottom galleryStanderPhotos  photogallryFolderDesign standarPortraitImage"
                    style={{
                      // backgroundImage: `url('${item.thumb}')`,
                      borderRadius: props.viewer_?.useRoundedCorners ? "0.80rem" : "0",
                      border: props.viewer_?.useLogoBox
                        ? `3px solid ${props.viewer_?.colorOfBox || "white"}`
                        : "",
                    }}
                  >
                    <img src={item.thumb} style={galleryLayoutStanderImageImgStyle} />
                  </div>
                ) : (
                  <div
                    onClick={() => handleFile(item.type, item.url, index)}
                    style={{ width: "100%", height: "100%" }}
                    className="shadow-bottom galleryStanderPhotos photogallryFolderDesign"
                  >
                    <div
                      style={gridImageStyle}
                      className="gallerythumbratio galleryLayoutStanderImage"
                    >
                      <img src={item.thumb} style={galleryLayoutStanderImageImgStyle} />
                    </div>
                  </div>
                )}
              </Grid>
            );
          })}
          <div style={{ height: "92px", width: "100%" }}></div>
        </Grid>
      )}

      <div className=""></div>
    </>
  );
};

export default StandardPhotos;
