export const ModuleId = (sideBarRoutes) => {
  const url = window.location.href;
  const parts = url.split("/");
  const baseModule = parts[4];
  const subModule = parts[5];

  let moduleItem = sideBarRoutes?.find((item) => {
    if (parts.length === 6 && item.initialName === subModule) {
      return true;
    } else if (parts.length < 6 && item.initialName === baseModule) {
      return true;
    }
    return false;
  });

  if (!moduleItem) {
    const moduleItemID = sideBarRoutes?.map((item) => {
      let id;
      if (parts.length === 6) {
        item?.collapse?.map((data) => {
          console.log("--aa--", subModule);
          console.log("--aa--ss====>", data);
          if (data?.initialName === subModule) {
            id = data.id;
          }
        });
      }
      return id;
    });
    moduleItem = { key: moduleItemID.filter((item) => item !== undefined) };
  }

  return moduleItem?.key;
};
