const checkImageHeight = (imageUrl) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      const height = img.height;
      resolve(height > 2000);
    };
    img.onerror = (error) => {
      reject(error);
    };
  });
};

export default checkImageHeight;
