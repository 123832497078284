/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { CircularProgress, Grid } from "@mui/material";
import { query } from "api";
import axios from "axios";
import { baseUrl } from "api";
import Sidenav from "examples/Sidenav";
import MDBox from "components/MDBox";
import PasswordModal from "components/PasswordModal";
import { useViewer } from "viewer";
import { ModuleId } from "utils/getModuleId";

function ModuleBase({ component, moduleId }) {
  const { viewer } = useViewer();

  const [viewer_, setViewer] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const [hasPassword, setHasPassword] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);
  const { sideBarRoutes } = useViewer();
  const url = window.location.href;

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${baseUrl}/viewer-modules/${ModuleId(sideBarRoutes)}?`)
      .then((res) => {
        setIsLoading(false);
        setHasLoaded(true);
        setViewer(res.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [url, sideBarRoutes]);

  useEffect(() => {
    if (viewer_.usePassword) {
      if (localStorage.getItem(`is${viewer_.name}Authenticated`)) {
        setHasPassword(false);
      } else {
        setHasPassword(true);
      }
    } else {
      setHasPassword(false);
    }
  }, [viewer_, moduleId]);

  return (
    <DashboardLayout>
      <Grid
        container
        alignItems="center"
        style={{
          display: "block",
          backgroundColor: `${
            viewer?.viewerFrameBackgroundColor
              ? `#${viewer?.viewerFrameBackgroundColor}`
              : "#3b3b42"
          }`,
        }}
        className="homePageCss"
      >
        {hasPassword && hasLoaded ? (
          <>
            {" "}
            <PasswordModal
              title="This module is password protected"
              viewer={viewer_}
              setHasPassword={setHasPassword}
            />
          </>
        ) : hasLoaded ? (
          component
        ) : null}
      </Grid>
    </DashboardLayout>
  );
}
export default ModuleBase;
