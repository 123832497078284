import React from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples

// NewUser page components
import UserInfo from "layouts/pages/event_enquery/userinfo";
import ModuleBase from "components/ModuleBase";
import { useViewer } from "viewer";

function EventPage() {
  const { viewer } = useViewer();
  return (
    <ModuleBase
      component={
        <div
          style={{
            backgroundColor: `#${viewer.viewerFrameBackgroundColor}`,
            overflow: "auto",
            scrollbarColor: "rgba(255, 255, 255, 0.2) transparent",
            scrollbarWidth: "thin",
          }}
          className="h-full w-full rounded-xl sm:overflow-hidden overflow-y-scroll custome_Scroll_save_bottom_overview"
        >
          <MDBox sx={{ mb: { xs: 1, sm: 1, md: 20 }, height: "100%" }}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ height: "100%", mt: { xs: 0, sm: 0, xl: 0, lg: 0 } }}
            >
              <Grid item xs={12} sm={6} className="px-5 py-3">
                <Formik>
                  <Form autoComplete="off">
                    <Card className="bg-[#f0f2f5]" sx={{ height: "100%" }}>
                      <MDBox p={3}>
                        <UserInfo />
                      </MDBox>
                    </Card>
                  </Form>
                </Formik>
              </Grid>
            </Grid>
          </MDBox>
        </div>
      }
    />
  );
}

export default EventPage;
