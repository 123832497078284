import React, { useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import { LinearProgress } from "@mui/material";
import Iframe from "react-iframe";
import { baseUrl } from "api";
import axios from "axios";
import ModuleBase from "components/ModuleBase";
import NoModule from "components/NoModule";
import { useViewer } from "viewer";
import { ModuleId } from "utils/getModuleId";

function StagingOptions() {
  const [viewer, setViewer] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const { sideBarRoutes } = useViewer();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${baseUrl}/viewer-modules/${ModuleId(sideBarRoutes)}?`)
      .then((res) => {
        setIsLoading(false);
        setViewer(res.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [sideBarRoutes]);
  return (
    <ModuleBase
      component={
        isLoading ? (
          <>
            {" "}
            <center>
              {/* <LinearProgress color="secondary" />{" "} */}
            </center>
          </>
        ) : viewer.stagingOptionsUrl ? (
          <Iframe
            url={viewer.stagingOptionsUrl}
            width="100%"
            height="100%"
            id="myId"
            // className=""

            // display="initial"
            position="relative"
          />
        ) : (
          <NoModule text="No staging options at the moment" />
        )
      }
    />
  );
}

export default StagingOptions;
