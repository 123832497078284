/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  LinearProgress,
  ListSubheader,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import { baseUrl } from "api";
import ModuleBase from "components/ModuleBase";
import NoModule from "components/NoModule";
import { useViewer } from "viewer";
import { ModuleId } from "utils/getModuleId";
import { Instagram } from "@mui/icons-material";

function InstagramPage() {
  const [viewer, setViewer] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const [feeds, setFeeds] = useState([]);
  const { sideBarRoutes } = useViewer();
  //   {
  //     "data": [
  //        {
  //           "id": "18220326541119858",
  //           "media_type": "IMAGE",
  //           "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/274681696_1614944402210942_3465856924021618359_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=n5QBmX_WB3EAX-FEMhp&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfD-lm5xo5wJitNQMcswNzEiOCf-PvWmcsaCIl7JdCY0JQ&oe=64BF7892"
  //        },
  //        {
  //           "id": "18281135785013477",
  //           "media_type": "IMAGE",
  //           "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/274545390_729000194951624_1332230933262928372_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=jNOURzKFznUAX-jcl50&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDtvHxAj-Q70CV_LQwsYipPE-FU4JN_CaCkY0gzU4mpwg&oe=64BE94D7"
  //        },
  //        {
  //           "id": "17914525622351464",
  //           "media_type": "IMAGE",
  //           "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/274515454_1954531391416785_1837832485388299237_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=WTk6YAg77N8AX9ajCQF&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDWrK-wZNUTwwdwSn0HHiwPlT7URzFqYXMcQIVHtFmsgQ&oe=64BF8774"
  //        },
  //        {
  //           "id": "17916215891476249",
  //           "media_type": "IMAGE",
  //           "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/274591212_929317304442363_5073823551742622206_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=JBIC2-ULIdsAX-NRcYD&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfBfKbkFkCFeaQAYkM359ZlgqlXjZI8QwEyEMZcVaZARyw&oe=64C0722D"
  //        },
  //        {
  //           "id": "18280358395042533",
  //           "media_type": "IMAGE",
  //           "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/274604571_1305752683256293_5459302762787929370_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=cZSn1Y9RCwUAX-7IO4i&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfAwve_XC_UuO3_cI08DXufKiu9I-ATeFwAdsh1npN7T7Q&oe=64BE90A1"
  //        },
  //        {
  //           "id": "17913969125348879",
  //           "media_type": "IMAGE",
  //           "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/274515413_645634703161572_3086968207890755611_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=vEBA90QbKKIAX9oknz_&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfBgDH3c9-8r3CNvPSF15jJDOxfibi4GQhNVVFmXu8_bJg&oe=64C06EAE"
  //        },
  //        {
  //           "id": "17909746925309901",
  //           "media_type": "IMAGE",
  //           "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/274479448_318372320315560_4836544909936278575_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=Uxo0nKROKkwAX-GqMQN&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfCkvdIkvxAIAWx8tWon5z_RfyoLTNNmlcaNlmF0GSLA8Q&oe=64C016E2"
  //        },
  //        {
  //           "id": "18081952186294188",
  //           "media_type": "IMAGE",
  //           "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/274486629_2032004890305695_4501724464949208924_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=k4gGniIbEC4AX8Rt6Kr&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfCvA4g41HKq_0UqL7-QZ5PViI8C_CFXkoBHE6S3jv9AYw&oe=64BF8C99"
  //        },
  //        {
  //           "id": "17921452256078764",
  //           "media_type": "IMAGE",
  //           "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/274574463_338890658209218_5023005756361782267_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=FaXLJmAjo_MAX_Av6DF&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfAnMIbCm4GebOum6QZdsxJiHXlybIDkwJPmgCyP-3WZBw&oe=64BFEFB3"
  //        },
  //        {
  //           "id": "17953910992631461",
  //           "media_type": "IMAGE",
  //           "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/274475166_118898527205056_6629530904418052323_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=TcSj567RwUgAX_0IwSJ&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfCxdCBqYdJwoSYiw_LfiocTf_mul7su1arzFosNqOjl8A&oe=64BF4F9E"
  //        },
  //        {
  //           "id": "17922136604191552",
  //           "media_type": "IMAGE",
  //           "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/274545019_1990441747796082_274994574716277267_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=rY-U92GR7KgAX-4CD_F&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDXSVaNDR5x_pkdKNHjXt7aXc2R98ZrZemzHWy39IJ0jA&oe=64BFED29"
  //        },
  //        {
  //           "id": "17880209792633090",
  //           "media_type": "IMAGE",
  //           "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/274603275_662268841784885_2337513941015658769_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=7KxJTPrK0m0AX8zDyxA&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfCIFrU06qOkKda_w8eI6lD18u-eR_loZeSZZmGpJR5RhQ&oe=64C00521"
  //        },
  //        {
  //           "id": "18192167470198998",
  //           "media_type": "IMAGE",
  //           "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/274479442_300387935418319_2872142915615993413_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=RB8qcIZOD20AX-W79RY&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDJB8VibLp25l4wUarvXhdfjNzz7T9ep6TYbNrFTXH1Uw&oe=64BFC8EB"
  //        },
  //        {
  //           "id": "17904579218518870",
  //           "media_type": "IMAGE",
  //           "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/274488595_253412930291597_8519735220058531962_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=YsmMly0OXIwAX9eN-yx&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfADQvSG2RXcoVdqt96vlYxBZC10aiSP6xU5J178PhUwLw&oe=64BE8530"
  //        },
  //        {
  //           "id": "17910321791461177",
  //           "media_type": "IMAGE",
  //           "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/274527666_947561039293400_1356005758584595346_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=CK8mK9uE-X4AX8RH6FB&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfC9TvKLj9h4u328Rw9IqilREdv7q1Sc_FVpe9daWT5xnw&oe=64BF0098"
  //        },
  //        {
  //           "id": "17950341811688538",
  //           "media_type": "IMAGE",
  //           "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/274683111_3156464497966924_3688324623722097938_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=Xva1V_nTrbwAX-xJA2x&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfAHRC0ELhpA9DkiyQTQs15BZ5Pw4F3JJjRDxp4iGixucg&oe=64BFEAAC"
  //        },
  //        {
  //           "id": "17961153145549841",
  //           "media_type": "IMAGE",
  //           "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/274543244_3241419212844914_6552072016110943164_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=AlJiX5Ikx0gAX94JluY&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfA6hPfudr9Wbe_wSNRTHgb854BzRYUzhBUvcCvbiTCh6w&oe=64BF3067"
  //        },
  //        {
  //           "id": "17919028988125506",
  //           "media_type": "IMAGE",
  //           "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/274478222_1002659420328845_2416901473352886652_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=SZq0hbR44GoAX_sHd--&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfAVRi4ZIiR674QQ1jD7O2RqGE7Nx_chJGpXk5rrQ6JuWQ&oe=64BF5F26"
  //        },
  //        {
  //           "id": "17874466307607725",
  //           "media_type": "IMAGE",
  //           "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/274560953_632613211365587_2289357475246072321_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=CALM7KWJF1sAX9vTG_m&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfAMzxLKI1KYF47u4DktHJrJn2dzx4M2PfoGNh9YvBKnBQ&oe=64BF519F"
  //        },
  //        {
  //           "id": "17968057594533318",
  //           "media_type": "IMAGE",
  //           "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/274636679_505930050959695_8621060234741719939_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=9jVWXAn8m44AX8TByMw&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfB2cm6rusP9lnIX0fP7BK019QVW_MQD2MEgETccH_e2Lw&oe=64C00911"
  //        },
  //        {
  //           "id": "17945863600801094",
  //           "media_type": "IMAGE",
  //           "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/274560099_4810461019066572_8366452628312520097_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=8pHKU_NrrrYAX8MWJ98&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfCoagWBTL5eczrnutc4HI1ZcadSFgxYZc_olXU-7Oa20g&oe=64BF81BB"
  //        },
  //        {
  //           "id": "17893916651577477",
  //           "media_type": "IMAGE",
  //           "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/274566512_3150255525296329_6712162052695664430_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=9KJDOaqoBmEAX-J4EJi&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfCbLzmiWk9zHmWnDWuvaY06tLU89xx7DSsKKPNijLt87A&oe=64BF5955"
  //        },
  //        {
  //           "id": "17940244246801006",
  //           "media_type": "IMAGE",
  //           "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/274515118_1002555603803053_6143563536042116213_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=UPRRpelSCAkAX9ZdiIQ&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfC2Is6gjipXFZyuN3jcNjG8osj-z-6VrPSfRSshTymTlw&oe=64C05744"
  //        },
  //        {
  //           "id": "18165517063175152",
  //           "media_type": "IMAGE",
  //           "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/274698777_473928460882435_4675437502033826807_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=d5kxiu_j2mEAX8Rl0t5&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfAgaPp_I2_FyAYEcqLxunt671daPqCoZMU3-zogqw1cqw&oe=64BEF541"
  //        }
  //     ],
  //     "paging": {
  //        "cursors": {
  //           "before": "QVFIUmt3OWUtcGNOUnZAlWkNZASkh5eTN1VXhyT3BYd3pKamNrOTN3S0tVVmI0bmtfeDJiTFdOREp2UDQzNWRJZAHg5c1c3YTZACX1Fyc1NRbGxLaFJNdjJVbGR3",
  //           "after": "QVFIUnU3X2ZATSkdENExHSGNvRlBteE1DZAURNckUwM0JLWnVWSDUyZA1A0TTJfN2F2Y2RDQkRNQ1FZAd0p2MzRaRllhZAnhJSkY0c29QMVYyRUZA3TVU1Rjc1NFNn"
  //        }
  //     }
  //  }
  useEffect(() => {
    const url = "https://graph.instagram.com/me/media";
    const fields = "id,caption,media_type,media_url,thumbnail_url";
    const accessToken = viewer.overviewContent;

    fetch(`${url}?fields=${fields}&access_token=${accessToken}`)
      .then((response) => response.json())
      .then((data) => {
        const userFeeds = data.data.map((item) => {
          return {
            img: item.media_url,
            title: "",
          };
        });
        setFeeds(userFeeds);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [viewer]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${baseUrl}/viewer-modules/${ModuleId(sideBarRoutes)}?`)
      .then((res) => {
        setIsLoading(false);
        setViewer(res.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [sideBarRoutes]);
  return (
    <ModuleBase
      component={
        isLoading ? (
          <>
            {" "}
            <center>{/* <LinearProgress color="secondary" />{" "} */}</center>
          </>
        ) : feeds.length > 0 ? (
          <ImageList sx={{ width: "100%", height: "100%" }} cols={5} gap={8} rowHeight={"auto"}>
            {feeds.map((item) => (
              <ImageListItem key={item.img}>
                <img src={item.img} srcSet={item.img} alt={item.title} loading="lazy" />
                <ImageListItemBar
                  subtitle={item.title}
                  actionIcon={
                    <IconButton
                      sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                      aria-label={`info about ${item.title}`}
                    >
                      <Instagram />
                    </IconButton>
                  }
                />
              </ImageListItem>
            ))}
          </ImageList>
        ) : (
          <NoModule text="No directions at the moment" />
        )
      }
    />
  );
}

export default InstagramPage;
