/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { LinearProgress, Grid } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import axios from "axios";
import { baseUrl } from "api";
import parse from "html-react-parser";
import ModuleBase from "components/ModuleBase";
import NoModule from "components/NoModule";
import { useViewer } from "viewer";
import { ModuleId } from "utils/getModuleId";

function Custom() {
  // eslint-disable-next-line no-unused-vars
  const [module, setModule] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const url = window.location.href;
  const parts = url.split("/");
  const module_id = parts[parts.length - 1];

  const { viewer } = useViewer();
  const { sideBarRoutes } = useViewer();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${baseUrl}/viewer-modules/${ModuleId(sideBarRoutes)}?`)
      .then((res) => {
        setErrorMessage("");
        setIsLoading(false);
        setModule(res.data);
      })
      .catch(() => {
        setErrorMessage("No custom data found");
        setIsLoading(false);
      });
  }, [sideBarRoutes]);

  return (
    <ModuleBase
      component={
        isLoading ? (
          <>
            {" "}
            <center>
              {/* <LinearProgress color="secondary" />{" "} */}
            </center>
          </>
        ) : module.codeEmbed ? (
          <Grid container alignItems="center">
            <div
              style={{ color: `#${viewer?.fontColor}` }}
              dangerouslySetInnerHTML={{
                __html: module?.codeEmbed,
              }}
            />
          </Grid>
        ) : (
          <NoModule text={errorMessage} />
        )
      }
    />
  );
}

export default Custom;
