/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-case-declarations */
import React, { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
// import SinglePhotos from "./SinglePhotos";
import SinglePhotos from "./SingleVideo";
import MinimalPhotos from "./MinimalPhotos";

import "./gallery.css";
import "./videoGallery.css";
import axios from "axios";
import { baseUrl } from "api";
import { useViewer } from "viewer";
import { LinearProgress } from "@mui/material";
import NoModule from "components/NoModule";
import { ModuleId } from "utils/getModuleId";

const Gallery = ({ FolderName, viewer_ }) => {
  const [tab, setTab] = React.useState("minimal");
  const [minimalStyle, SetminimalStyle] = React.useState({ filter: "brightness(0.5)" });
  const [singleStyle, SetsingleStyle] = React.useState({ filter: "brightness(1)" });

  const handleChange = (value, valueBright) => {
    SetminimalStyle({
      filter: `brightness(${valueBright[0]})`,
    });
    SetsingleStyle({
      filter: `brightness(${valueBright[1]})`,
    });
    setTab(value);
  };

  useEffect(() => {
    if (FolderName.length === 1 && !viewer_.displayFolder) {
      setTab("single");
    } else {
      setTab("minimal");
    }
    // if (FolderName.length > 1) {
    //   setTab("minimal");
    // }
  }, [viewer_, FolderName]);

  const getGalleryLayout = () => {
    switch (tab) {
      case "minimal":
        return <MinimalPhotos control={handleChange} photos={FolderName} viewer_={viewer_} />;
      case "single":
        let singleType = localStorage.getItem("videoName") || false;
        let singleUrl = localStorage.getItem("videoUrl") || false;

        if (singleUrl != false) {
          localStorage.removeItem("url");
          let defaultPhotoObj = { type: singleType, url: singleUrl };
          return (
            <SinglePhotos defaultPhoto={defaultPhotoObj} photos={FolderName} viewer_={viewer_} />
          );
        } else {
          return <SinglePhotos photos={FolderName} viewer_={viewer_} />;
        }

      default:
        break;
    }
  };

  console.log("----", FolderName.length);
  console.log("----", viewer_.displayFolder);
  const getValueIconname = () => {
    switch (tab) {
      case "minimal":
        return (
          <div
            className="iconButtonGroup mobileheightzero"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              opacity: "0",
              visibility: "hidden",
            }}
          >
            {FolderName.length == 1 ? (
              <>
                {viewer_.displayFolder ? (
                  <>
                    <IconButton
                      onClick={() => {
                        handleChange("minimal", [0.5, 1, 1]);
                      }}
                      style={{ color: "red" }}
                    >
                      <img src="/images/grid4.png" style={minimalStyle} className="iconbuttonDG" />
                    </IconButton>
                  </>
                ) : null}
              </>
            ) : null}
            {FolderName.length > 1 ? (
              <>
                <IconButton
                  onClick={() => {
                    handleChange("minimal", [0.5, 1, 1]);
                  }}
                  style={{ color: "red" }}
                >
                  <img src="/images/grid4.png" style={minimalStyle} className="iconbuttonDG" />
                </IconButton>
              </>
            ) : null}
            <IconButton
              style={{ zIndex: "9999" }}
              onClick={() => {
                handleChange("single", [1, 1, 0.5]);
              }}
            >
              <img style={singleStyle} src="/images/grid1.png" className="iconbuttonDG" />
            </IconButton>{" "}
          </div>
        );
      default:
        return (
          <div
            className={
              tab == "single" ? "iconButtonGroup iconButtonGroupTopSpaceHandle" : "iconButtonGroup"
            }
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {FolderName.length == 1 ? (
              <>
                {viewer_.displayFolder ? (
                  <>
                    <IconButton
                      onClick={() => {
                        handleChange("minimal", [0.5, 1, 1]);
                      }}
                      style={{ color: "red" }}
                    >
                      <img src="/images/grid4.png" style={minimalStyle} className="iconbuttonDG" />
                    </IconButton>
                  </>
                ) : null}
              </>
            ) : null}
            {FolderName.length > 1 ? (
              <>
                <IconButton
                  onClick={() => {
                    handleChange("minimal", [0.5, 1, 1]);
                  }}
                  style={{ color: "red" }}
                >
                  <img src="/images/grid4.png" style={minimalStyle} className="iconbuttonDG" />
                </IconButton>
              </>
            ) : null}
            <IconButton
              style={{ zIndex: "9999" }}
              onClick={() => {
                handleChange("single", [1, 1, 0.5]);
              }}
            >
              <img style={singleStyle} src="/images/grid1.png" className="iconbuttonDG" />
            </IconButton>{" "}
          </div>
        );
    }
  };

  return (
    <>
      {getValueIconname()}
      <div
        className={tab == "single" ? "" : "galleryLayoutScroll"}
        style={
          tab == "single"
            ? {
                width: "100%",
              }
            : {
                padding: "0px 51px 0px 47px",
              }
        }
      >
        {getGalleryLayout()}
      </div>
    </>
  );
};

export default Gallery;
