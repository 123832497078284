/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import Gallery from "components/FloorPlan";
import ModuleBase from "components/ModuleBase";

function FloorPlans({ moduleType }) {
  return <ModuleBase component={<Gallery moduleType="floor-plans" />} />;
}
export default FloorPlans;
