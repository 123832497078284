/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { CircularProgress, LinearProgress } from "@mui/material";
import Iframe from "react-iframe";
import axios from "axios";
import { baseUrl } from "api";
import ModuleBase from "components/ModuleBase";
import NoModule from "components/NoModule";
import { useViewer } from "viewer";
import { ModuleId } from "utils/getModuleId";
import PdfViewer from "components/PDFViewer";

function SalesDeck() {
  const [viewer_, setViewer] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const { viewer, sideBarRoutes } = useViewer();
  const url = window.location.href;

  useEffect(() => {
    setIsLoading(true);
    setViewer({});
    if (ModuleId(sideBarRoutes).length > 0) {
      axios
        .get(`${baseUrl}/viewer-modules/${ModuleId(sideBarRoutes)}?`)
        .then((res) => {
          setIsLoading(false);
          console.log("from data server", ModuleId(sideBarRoutes));
          console.log("from data server", res.data);
          setViewer(res.data);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [url, sideBarRoutes]);

  return (
    <ModuleBase
      component={
        isLoading ? (
          <>
            {" "}
            <div className="h-full w-full rounded-xl sm:overflow-hidden flex flex-col 2xl:justify-center items-center space-y-5 2xl:space-y-10 p-5 lg:p-10 overflow-y-scroll comingsoontext">
              <>
                <div className="text-center">
                  <CircularProgress
                    style={{
                      color: viewer?.windowColor ? `#${viewer?.windowColor}` : "white",
                    }}
                  />
                </div>
              </>
            </div>
          </>
        ) : viewer_.salesDeck ? (
          <PdfViewer pdfUrl={viewer_.salesDeck} />
        ) : (
          <NoModule text="No sales decks at the moment" />
        )
      }
    />
  );
}

export default SalesDeck;
