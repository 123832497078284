import { useEffect } from "react";
import "./style.css";

export default function NotFoundPage() {
  useEffect(() => {
    // Redirect to https://www.viewlio.com
    window.location.href = "https://www.viewlio.com";
  }, []);

  // Empty return since the component will redirect before rendering anything
  return null;
}
