import React, { useEffect, useMemo } from "react";
import { useMaterialUIController, setMiniSidenav } from "context";
import { Navigate, Route, Routes, useNavigate, useParams } from "react-router-dom";
import Sidenav from "examples/Sidenav";
import { useState } from "react";
// import brandWhite from "assets/images/wi_logo.png";
// import brandDark from "assets/images/wi_logo_dark.png";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import MessageRoundedIcon from "@mui/icons-material/MessageRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import CodeRoundedIcon from "@mui/icons-material/CodeRounded";
import routes from "routes";
import { createContext } from "react";
import { useContext } from "react";
import { query } from "api";
import { endpoints } from "api";
import { Modal, Box, ListItemText, ListItemIcon, Grid, ListItemButton } from "@mui/material";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { availableModules } from "routes";
import {
  CircleXFill,
} from "akar-icons";
export const ViewerContext = createContext({});
export function useViewer() {
  return useContext(ViewerContext);
}

export default function Viewer() {
  const [controller, dispatch] = useMaterialUIController();
  const [openShareModal, setOpenShareModal] = React.useState(false);

  const { miniSidenav, layout, sidenavColor } = controller;

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [password, setPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSideBarLoading, setIsSideBarLoading] = useState(false);
  const [mainDashboard, setMainDashboard] = useState(false);

  const [isError, setIsError] = useState(false);
  const [viewer, setViewer] = useState({});
  const viewerName = useParams().viewerName;
  const url = window.location.href;
  const navigate = useNavigate();

  useEffect(() => {
    setMiniSidenav(dispatch, false);
  }, []);
  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  useEffect(() => {
    query
      .get(
        endpoints.viewers.getAll(
          {},
          { slug: viewerName.toLowerCase().replaceAll(" ", "-"), isActive: true }
        )
      )
      .then((res) => {
        // setFetching(false);
        if (res.data.length) {
          localStorage.setItem("menuColor", res.data[0]?.menuColor);
          localStorage.setItem(
            "menuColorGradientSecondColor",
            res.data[0]?.menuColorGradientSecondColor
          );

          const shouldReload =
            (localStorage.getItem("windowColor") !== res.data[0]?.windowColor ||
              localStorage.getItem("fontColor") !== res.data[0]?.fontColor) &&
            res.data[0]?.windowColor &&
            res.data[0]?.fontColor;

          if (shouldReload) {
            localStorage.setItem(
              "windowColor",
              res.data[0]?.windowColor ? res.data[0]?.windowColor : ""
            );
            localStorage.setItem("fontColor", res.data[0]?.fontColor ? res.data[0]?.fontColor : "");

            window.location.reload();
          }
          setViewer(res.data[0]);
        } else {
          navigate("/not-found");
        }
      })
      .catch(() => {});
  }, [viewerName, url]);

  useEffect(() => {
    if (localStorage.getItem("viewerName") !== viewerName) {
      localStorage.clear();
      localStorage.setItem("viewerName", viewerName);
    }
  });

  const [sideBarRoutes, setSideBarRoutes] = useState([]);
  const [brand, setBrand] = useState([]);
  useEffect(() => {
    setIsSideBarLoading(true);
    query
      .get(
        endpoints.viewers.getAll(
          {},
          { slug: viewerName.toLowerCase().replaceAll(" ", "-"), isActive: true }
        )
      )
      .then((res) => {
        // setFetching(false);
        if (res.data.length) {
          // setViewer(res.data[0]);
          setBrand(res.data[0].logoUrl);
          query
            .get(
              endpoints.modules.getAll(
                {},
                {
                  viewerId: res.data[0].id,
                  isDisabled: false,
                  displayInLeftMenu: true,
                }
              )
            )
            .then((res_) => {
              // setFetchingModules(false);
              if (res_.data.length) {
                const modules = res_.data;
                const sorted = res_.data.sort((a, b) => a.order - b.order);
                // .filter((item) => !item.parent);
                if (!sorted?.length) {
                  return [];
                }

                const unknownItems = sorted.filter((x) => !x.id);

                const items = sorted;

                // Step 1: Parse the JSON
                const parsedItems = sorted;

                // Step 2: Create an object of items with IDs as keys
                const itemsMap = {};
                parsedItems?.forEach((item) => {
                  itemsMap[item.id] = item;
                });

                // Step 3-5: Modify items that have parent IDs
                parsedItems?.forEach((item) => {
                  if (item.parent) {
                    const parentItem = itemsMap[item.parent];
                    if (parentItem) {
                      if (!parentItem.collapse) {
                        parentItem.collapse = [];
                      }
                      parentItem.collapse.push(item);
                    }
                  }
                });

                // Step 6: Return the modified array of items

                const updatedArray = modules.filter((item) => !items.includes(item.id));
                const final = updatedArray.filter((item) => !item.parent);

                const module = final[0];
                let route = module.type;
                if (availableModules.includes(module.menuTitle)) {
                  route = module.menuTitle + "/" + module.name;
                } else {
                  route = module.menuTitle?.replaceAll(" ", "-")?.toLowerCase() + "/" + module.name;
                }
                if (availableModules.includes(module.type)) {
                  route = module.type;
                }

                setMainDashboard(route);
                const temp = final.map((module) => {
                  let route = module.type;
                  if (availableModules.includes(module.menuTitle)) {
                    route = module.menuTitle + "/" + module.name;
                  } else {
                    route =
                      module.menuTitle?.replaceAll(" ", "-")?.toLowerCase() + "/" + module.name;
                  }
                  if (availableModules.includes(module.type)) {
                    route = module.type;
                  }
                  return {
                    type: "collapse",
                    name: module.label,
                    // name: module.label.toUpperCase(),
                    key: module.id,
                    initialName: module.name.replaceAll(" ", "-"),
                    noCollapse: module?.collapse?.length > 0 ? false : true,
                    // route: route + "/" + module.id,
                    route: route,
                    collapse: module?.collapse?.map((navItem) => {
                      let route = navItem.type;
                      if (modules.includes(module.menuTitle)) {
                        route = navItem.menuTitle + "/" + navItem.name;
                      } else {
                        if (navItem.menuTitle) {
                          route =
                            navItem.menuTitle?.replaceAll(" ", "-")?.toLowerCase() +
                            "/" +
                            navItem.name.replaceAll(" ", "-");
                        } else {
                          route = navItem.type + "/" + navItem.name.replaceAll(" ", "-");
                        }
                      }
                      if (modules.includes(navItem.type)) {
                        route = navItem.type;
                      }
                      return {
                        ...navItem,
                        key: navItem.id,
                        initialName: navItem.name.replaceAll(" ", "-"),
                        // name: navItem.label.toUpperCase(),
                        name: navItem.label,
                        // route: route + "/" + navItem.id,
                        route: route,
                      };
                    }),
                  };
                });

                setIsSideBarLoading(false);
                // console.log("----", [...unknownItems, ...temp]);
                setSideBarRoutes([...unknownItems, ...temp]);
                // return [...unknownItems, ...temp];
              }
            })
            .catch(() => {
              setIsSideBarLoading(false);
            });
        } else {
          // navigate("/not-found");
        }
      })
      .catch(() => {
        setIsSideBarLoading(false);
      });
  }, []);

  const contextValue = useMemo(
    () => ({
      viewer,
      sideBarRoutes,
    }),
    [viewer, sideBarRoutes]
  );
  let openLink = (link) => {
    window.open(link, "_blank");
  };
  let currentLink = window.location.href;
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    outline: "none",
    boxShadow: 24,
    p: 4,
  };

  function copySiteLink() {
    let link = window.location.href;
    copyTextToClipboard(link);
  }
  function copyEmbed() {
    let link = window.location.href;
    let string = `<iframe width="500" height="500" src="${link}" title="${document.title}" frameborder="0" allowfullscreen></iframe>`;
    copyTextToClipboard(string);
  }

  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text command was " + msg);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  }
  function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }

  return (
    <ViewerContext.Provider value={contextValue}>
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            // brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Material Dashboard PRO"
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            viewer={viewer}
            brand={brand}
            routes={sideBarRoutes}
            isLoading={isSideBarLoading}
            mainDashboard={mainDashboard}
            setOpenShareModal={setOpenShareModal}
          />
        </>
      )}
      <Modal
        open={viewer.usePassword ? (localStorage.getItem("isAuthenticated") ? false : true) : false}
        onClose={() => {}}
        style={{ zIndex: "9999999999999" }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalBgBox" sx={style}>
          <center>
            <h1>This Gallery is Password Protected.</h1>
          </center>
          <br />
          <MDBox mb={2}>
            <MDInput
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              label="Enter Password"
              fullWidth
            />
          </MDBox>
          {isError ? (
            <>
              <MDTypography style={{ color: "red" }}>Incorrect password </MDTypography>
            </>
          ) : null}
          <MDBox mt={4} mb={1}>
            <MDButton
              onClick={() => {
                setIsLoading(true);
                query
                  .post(endpoints.viewers.authenticate(), { id: viewer.id, password })
                  .then(() => {
                    localStorage.setItem("isAuthenticated", true);
                    window.location.reload();
                    setIsLoading(false);
                    setIsError(false);
                  })
                  .catch(() => {
                    setIsError(true);
                    setIsLoading(false);
                  });
              }}
              variant="gradient"
              color="info"
              fullWidth
            >
              {isLoading ? "authenticating..." : "proceed"}
            </MDButton>
          </MDBox>
        </Box>
      </Modal>

      <Modal
        open={openShareModal}
        onClose={() => {
          setOpenShareModal(false);
        }}
        style={{ zIndex: "9999999999999" }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalBgBox" sx={style}>
        <Grid item xs={12} sm={6} xl={6}>
  <ListItemButton onClick={() => setOpenShareModal(false)} className="removehove">
    <ListItemIcon >
    <CircleXFill className="closebuttonmodal" strokeWidth={2} size={36} />
    </ListItemIcon>
  </ListItemButton>
</Grid>




          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} xl={6}>
              <ListItemButton
                onClick={() =>
                  openLink(
                    `mailto:?&subject=Check%20Out%20${viewer.name}%20Viewlio%20Gallery!%21&body=View%20${viewer.name}%20Viewlio%20Gallery%20here%20-%20${currentLink}`
                  )
                }
              >
                <ListItemIcon>
                  <MailOutlineRoundedIcon fontSize="medium" />
                </ListItemIcon>
                <ListItemText primary="Email" />
              </ListItemButton>
            </Grid>
            <Grid item xs={12} sm={6} xl={6}>
              <ListItemButton
                onClick={() =>
                  openLink(
                    `sms:?&body=Check%20out%20${viewer.name}%20Viewlio%20Gallery%20here%20-%20${currentLink}`
                  )
                }
              >
                <ListItemIcon>
                  <MessageRoundedIcon fontSize="medium" />
                </ListItemIcon>
                <ListItemText primary="Text" />
              </ListItemButton>
            </Grid>
            <Grid item xs={12} sm={6} xl={6}>
              <ListItemButton onClick={() => copySiteLink()}>
                <ListItemIcon>
                  <ContentCopyRoundedIcon fontSize="medium" />
                </ListItemIcon>
                <ListItemText primary="Copy Link" />
              </ListItemButton>
            </Grid>
            <Grid item xs={12} sm={6} xl={6}>
              <ListItemButton onClick={() => copyEmbed()}>
                <ListItemIcon>
                  <CodeRoundedIcon fontSize="medium" />
                </ListItemIcon>
                <ListItemText primary="Embed" />
              </ListItemButton>
            </Grid>
            <Grid item xs={12} sm={6} xl={6}>
              <ListItemButton
                onClick={() =>
                  openLink(
                    `https://www.facebook.com/sharer/sharer.php?u=${currentLink}&t=Check%20out%20${viewer.name}%20Viewlio%20Gallery&quote=Check%20out%20${viewer.name}%20Viewlio%20Gallery&body=Check%20out%20${viewer.name}%20Viewlio%20Gallery`
                  )
                }
              >
                <ListItemIcon>
                  <FacebookOutlinedIcon fontSize="medium" />
                </ListItemIcon>
                <ListItemText primary="Facebook" />
              </ListItemButton>
            </Grid>
            <Grid item xs={12} sm={6} xl={6}>
              <ListItemButton
                onClick={() =>
                  openLink(
                    `https://twitter.com/intent/tweet?text=Check%20Out%20${viewer.name}%20Viewlio%20Gallery%21 ${currentLink}&related=AddToAny,micropat`
                  )
                }
              >
                <ListItemIcon>
                  <svg
                    width="16"
                    height="20"
                    viewBox="0 0 300 300"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M178.57 127.15 290.27 0h-26.46l-97.03 110.38L89.34 0H0l117.13 166.93L0 300.25h26.46l102.4-116.59 81.8 116.59h89.34M36.01 19.54H76.66l187.13 262.13h-40.66" />
                  </svg>
                </ListItemIcon>
                <ListItemText primary="X" />
              </ListItemButton>
            </Grid>

            <Grid item xs={12} sm={6} xl={6}>
              <ListItemButton
                onClick={() =>
                  openLink(`https://www.linkedin.com/sharing/share-offsite/?url=${currentLink}`)
                }
              >
                <ListItemIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="24"
                    viewBox="0 0 448 512"
                  >
                    <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
                  </svg>
                </ListItemIcon>
                <ListItemText primary="Linkedin" />
              </ListItemButton>
            </Grid>

            <Grid item xs={12} sm={6} xl={6}>
              <ListItemButton
                onClick={() =>
                  openLink(
                    `https://wa.me/?text=Check out ${viewer.name} Viewlio Gallery! ${currentLink}`
                  )
                }
              >
                <ListItemIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="24"
                    fill="currentColor"
                    className="bi bi-whatsapp"
                    viewBox="0 0 16 16"
                  >
                    <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                  </svg>
                </ListItemIcon>
                <ListItemText primary="WhatsApp" />
              </ListItemButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to={`default/landing`} />} />
      </Routes>
    </ViewerContext.Provider>
  );
}
