/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Box } from "@mui/material";
import MDInput from "components/MDInput";
import { query } from "api";
import { endpoints } from "api";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

function PasswordModal({ title, viewer, setHasPassword }) {
  const [password, setPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    outline: "none",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Box className="modalBgBox" sx={style}>
        <center>
          <h1>{title}</h1>
        </center>
        <br />
        <MDBox mb={2}>
          <MDInput
            type="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            label="Enter Password"
            fullWidth
          />
        </MDBox>
        {isError ? (
          <>
            <MDTypography style={{ color: "red" }}>Incorrect password </MDTypography>
          </>
        ) : null}
        <MDBox mt={4} mb={1}>
          <MDButton
            onClick={() => {
              setIsLoading(true);
              query
                .post(endpoints.modules.authenticate(), { id: viewer.id, password })
                .then(() => {
                  localStorage.setItem(`is${viewer.name}Authenticated`, true);
                  window.location.reload()

                  setIsLoading(false);
                  setIsError(false);
                  setHasPassword(false)
                })
                .catch(() => {
                  setIsError(true);
                  setIsLoading(false);
                });
            }}
            variant="gradient"
            color="info"
            fullWidth
          >
            {isLoading ? "authenticating..." : "proceed"}
          </MDButton>
        </MDBox>
      </Box>
      {/* <Modal
        open={
          viewer.usePassword
            ? localStorage.getItem(`is${viewer.name}Authenticated`)
              ? false
              : true
            : false
        }
        onClose={() => {}}
        style={{ zIndex: "9999999999999" }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
      </Modal> */}
    </>
  );
}

export default PasswordModal;
