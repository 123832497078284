/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import VideoGallery from "components/VideoGallery/index";
import ModuleBase from "components/ModuleBase";
import axios from "axios";
import { baseUrl } from "api";
import { ModuleId } from "utils/getModuleId";
import { useViewer } from "viewer";
import { LinearProgress } from "@mui/material";
import NoModule from "components/NoModule";

function Videogallery() {
  const { viewer, sideBarRoutes } = useViewer();
  const [FolderName, setfolderName] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${baseUrl}/video-gallery-folders?viewerId=${ModuleId(sideBarRoutes)}`)
      .then((res) => {
        setIsLoading(false);
        const folderData = res?.data
          ?.sort((a, b) => a.order - b.order)
          .map((item) => {
            return {
              ...item,
              // type: item.name,
              // caption: item.name,
              // url: item.videoUrl,
              // player_type: item.videoUrl.includes("youtu") ? "youtube" : "vimo",
              thumb: item.thumbnail,
              hasThumbnail: item.hasThumbnail,
              type: item.name,
              caption: item.name,
              url: item.videoUrl,
              player_type: item.videoUrl.includes("youtu") ? "youtube" : "vimo",
            };
          });
        setfolderName(folderData);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [viewer, sideBarRoutes]);

  const [viewer_, setViewer] = useState({});

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${baseUrl}/viewer-modules/${ModuleId(sideBarRoutes)}?`)
      .then((res) => {
        setIsLoading(false);
        setViewer(res.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [sideBarRoutes]);

  return (
    <>
      {" "}
      {isLoading && <center>{/* <LinearProgress color="secondary" /> */}</center>}
      <ModuleBase
        component={
          !isLoading && FolderName.length === 0 ? (
            <>
              <NoModule text="No videos at the moment" />
            </>
          ) : (
            <VideoGallery FolderName={FolderName} viewer_={viewer_} />
          )
        }
      />{" "}
    </>
  );
}
export default Videogallery;
