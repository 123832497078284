/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import React from "react";
import Grid from "@mui/material/Grid";
import { NavLink } from "react-router-dom";
import { useViewer } from "viewer";

const MinimalPhotos = (props) => {
  function handleFolder(folderName) {
    props.control("standard", [1, 0.5, 1]);
    localStorage.setItem("folderName", folderName);
  }

  // function navLinkOnClick(event) {
  //   event.preventDefault();
  // }

  const { viewer } = useViewer();
  return (
    <Grid container>
      {props.photos?.map((item) => {
        return (
          <Grid item xs={12} sm={6} lg={4} className="globalImageGall">
            {/* <NavLink onClick={(e) => navLinkOnClick(e)} to={"/floor-plans/" + item.type}> */}
            <NavLink to={"/floor-plans/" + item.type}>
              <div
                onClick={() => {
                  props.setFiles(item.files);
                  handleFolder("File");
                }}
                className="photoGalleryImageCaptionSizeFix photogallryFolderDesign"
                style={{ width: "100%", height: "100%" }}
              >
                <div className="grid_image_size">
                  <img src={item.thumb} />
                </div>
                <h3 style={{ color: `#${viewer?.fontColor}` }} className="photoGalleryImageCaption">
                  {item.caption}
                </h3>
              </div>
            </NavLink>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default MinimalPhotos;
//test
