/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { useViewer } from "viewer";

function NoModule({ text }) {
  const { viewer } = useViewer();
  return (
    <>
      <div className="h-full w-full rounded-xl sm:overflow-hidden flex flex-col 2xl:justify-center items-center space-y-5 2xl:space-y-10 p-5 lg:p-10 overflow-y-scroll comingsoontext">
        <>
          <div className="text-center">
            <p style={{ color: `#${viewer?.fontColor}` }} className="text-white uppercase ">
              {/* CONTENT COMING SOON */}
            </p>
          </div>
        </>
      </div>
    </>
  );
}

export default NoModule;
