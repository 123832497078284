import envConfigs from "envConfigs";

const urls = {
  live: "https://app.viewlio.com/api/v1.0",
  local_live: "https://app.viewlio.com/api/v1.0",
  local: "http://localhost:3001/api/v1.0",
  dev: "https://clownfish-app-btyb7.ondigitalocean.app/api/v1.0",
  local_dev: "https://clownfish-app-btyb7.ondigitalocean.app/api/v1.0",
};

const baseUrl = urls[envConfigs];

export { baseUrl };
