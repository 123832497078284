/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import React from "react";
import Grid from "@mui/material/Grid";
import { NavLink } from "react-router-dom";
// import { useViewer } from "viewer";

const MinimalPhotos = (props) => {
  function handleFolder(folderName) {
    props.control("standard", [1, 0.5, 1]);
    localStorage.setItem("folderName", folderName);
  }

  function navLinkOnClick(event) {
    event.preventDefault();
  }

  // const { viewer } = useViewer();

  const gridImageStyle = {
    borderRadius: props.viewer_?.useRoundedCorners ? "0.75rem" : "0", // Set border radius to 0.75rem if useRoundedCorners is true, otherwise, set it to 0.
    border: props.viewer_?.useLogoBox ? `3px solid ${props.viewer_?.colorOfBox || "white"}` : "", // Set the border color to props.viewer_?.colorOfBox if it exists, otherwise, set it to white.
    width: "88%",
    maxWidth: "100%",
    overflow: "hidden",
  };
  return (
    <Grid container>
      {props.photos?.map((item) => {
        return (
          <Grid item xs={12} sm={6} xl={6} xxl={4} lg={6} className="globalImageGall">
            <NavLink >
              <div
                onClick={() => {
                  props.setFiles(item.files);
                  props.setModuleId(item.moduleId);
                  handleFolder("File");
                }}
                // onClick={() => handleFolder(item.type)}
                className="photoGalleryImageCaptionSizeFix photogallryFolderDesign"
                style={{ width: "100%", height: "100%" }}
              >
                <div style={gridImageStyle} className="folderthumbratio">
                  <img src={item.thumb} alt="Thumbnail" />
                </div>
                <h3
                  style={{ color: props.viewer_?.textColor || "white" }}
                  className="photoGalleryImageCaption"
                >
                  {item.caption}
                </h3>
              </div>
            </NavLink>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default MinimalPhotos;
//test
