/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { MaterialUIControllerProvider } from "context";
import "./tailwind.css";
import { CssBaseline } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <App />
      </StyledEngineProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// document.querySelector("#hamburger").addEventListener("click", function () {
//   document.querySelector(".hideSideNavClick").firstChild.classList.toggle("app-d-none");
//   if (innerWidth <= 1236) {
//     document.querySelector(".MuiBox-root.css-aim41u").classList.add("removeLeftMargin");
//   } else {
//     document.querySelector(".MuiBox-root.css-aim41u").classList.toggle("removeLeftMargin");
//   }
// });

//Check if user first time
if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
  const localData = getCookie("firstTime") || false;
  const path = window.location.pathname.split("/").pop();
  if (localData == false) {
    document.querySelector("#hamburger").click();
    document.cookie = "firstTime=true; max-age=600; path=/;";
  }
  //Close sidenav when outside click
  window.addEventListener("click", function (e) {
    if (
      document.querySelector(".hideSideNavClick").classList.contains("css-srq5h2-MuiDrawer-docked")
    ) {
      if (!document.querySelector(".hideSideNavClick").contains(e.target)) {
        if (!document.querySelector("#hamburger").contains(e.target)) {
          document.querySelector("#hamburger").click();
        }
      }
    }
  });
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return false;
}
document
  .querySelector(".custome_Scroll_save_bottom")
  .setAttribute("style", `height: ${innerHeight - 390}px !important`);

let defaultScreenSize = innerHeight;
setInterval(() => {
  const element = document.querySelector(".custome_Scroll_save_bottom");

  if (element) {
    if (defaultScreenSize !== innerHeight) {
      defaultScreenSize = innerHeight;
      element.setAttribute("style", `height: ${innerHeight - 390}px !important`);
    }
  } else {
    console.error("Element with class .custome_Scroll_save_bottom not found");
  }
}, 1000);

// Fix for 100vh issue in Safari mobile
const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty("--appHeight", `${window.innerHeight}px`);
  if (window.innerWidth > 1200) {
    // document.querySelector(".hideSideNavClick").style.display = "block";
  }
  if (document.querySelector(".sliderWrapper.responsiveHeight")) {
    document.querySelector(".sliderWrapper.responsiveHeight").style.paddingTop = marginCode();
  }
};
window.addEventListener("resize", appHeight);
appHeight();

// Fix Slider middle
window.addEventListener("click", () => {
  if (document.querySelector(".sliderWrapper.responsiveHeight")) {
    document.querySelector(".sliderWrapper.responsiveHeight").style.paddingTop = marginCode();
  }
});

let marginCode = () => {
  let topGroupButton =
    parseFloat(
      window
        .getComputedStyle(document.querySelector(".iconButtonGroup"), null)
        .getPropertyValue("height")
    ) + 10;
  let sliderInner = innerHeight - topGroupButton;
  let sliderHeight = parseFloat(
    window.getComputedStyle(document.querySelector(".slideRow"), null).getPropertyValue("height")
  );
  // let extraSpace = ((sliderInner - sliderHeight) / 2) - 20;
  // return extraSpace;
  let cssText = `calc((((${sliderInner}px - 1rem) - ${sliderHeight}px) / 2) - 40px)`;
  return cssText;
};

function iOS() {
  return ["iPhone Simulator", "iPod Simulator", "iPhone", "iPod"].includes(navigator.platform);
}

if (iOS()) {
  // document.querySelector("#fullscreen").setAttribute("style", "display: none !important");
}

localStorage.setItem("isNavBarOpen", true);
setInterval(() => {
  let url = window.location.pathname;
  let filename = url.substring(url.lastIndexOf("/") + 1);
  if (filename == "event-inquiry" && window.innerWidth < 578) {
    if (
      document.querySelector(".MuiBox-root.css-3ct2sb") ||
      document.querySelector(".MuiBox-root.css-16tpajl")
    ) {
      let done =
        document.querySelector(".MuiBox-root.css-3ct2sb") ||
        document.querySelector(".MuiBox-root.css-16tpajl");
      // done.setAttribute("style", "overflow: hidden;overflow-y: auto;border-radius: 10px");
    }
  } else {
    if (
      document.querySelector(".MuiBox-root.css-3ct2sb") ||
      document.querySelector(".MuiBox-root.css-16tpajl")
    ) {
      let done =
        document.querySelector(".MuiBox-root.css-3ct2sb") ||
        document.querySelector(".MuiBox-root.css-16tpajl");
      // done.setAttribute("style", "overflow: hidden;overflow-y: hidden;border-radius: 10px");
    }
  }
}, 1000);

// document.addEventListener("fullscreenchange", fullscreenchanged);
