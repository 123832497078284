/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import React from "react";
import Grid from "@mui/material/Grid";
// import { useViewer } from "viewer";

const MinimalPhotos = (props) => {
  function handleFoldeFile(folderName, url) {
    props.control("single", [1, 0.5]);
    localStorage.setItem("videoName", folderName);
    localStorage.setItem("videoUrl", url);
  }

  const Thumbnail_getter = (src, player_type, item) => {
    if (player_type == "vimo") {
      return item.thumb;
    } else {
      if (item.hasThumbnail == "false") {
        return item.thumb;
      } else {
        // let youtube_video_id = src.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop();
        // return `//img.youtube.com/vi/${youtube_video_id}/maxresdefault.jpg`;
        let youtube_video_id;
        if (item.url.includes("embed")) {
          youtube_video_id = src.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop();
        } else {
          const youtubeUrl = new URL(item.url);
          youtube_video_id = youtubeUrl.searchParams.get("v") || youtubeUrl.pathname.substring(1);
        }

        return `//img.youtube.com/vi/${youtube_video_id}/maxresdefault.jpg`;
      }
    }
  };

  // const { viewer } = useViewer();
  let border = "";
  if (props?.viewer_?.useLogoBox) {
    border = "3px solid";
  }
  return (
    <Grid container>
      {props.photos?.map((item) => {

        return (
          <Grid item xs={12} sm={6} xl={6} xxl={4} lg={6} className="globalImageGall">
            <div
              onClick={() => handleFoldeFile(item.type, item.url)}
              className="photoGalleryImageCaptionSizeFixVid photogallryFolderDesign"
              style={{ width: "100%", height: "100%" }}
            >
              <div
                style={{
                  border: `${border} ${props?.viewer_?.colorOfBox || "white"}`,
                  borderRadius: `${props?.viewer_?.useRoundedCorners ? "0.75rem" : ""}`,
                }}
                className="grid_image_size_vid make_minimum_thumb vidoethumbratio"
              >
                <img src={Thumbnail_getter(item.url, item.player_type, item)} />
              </div>
              <h3
                style={{ color: props.viewer_?.textColor || "white" }}
                className="photoGalleryImageCaption"
              >
                {item.caption}
              </h3>
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default MinimalPhotos;
//test
