/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Logo from "./logo.png";
import axios from "axios";
import { baseUrl } from "api";
import { LinearProgress } from "@mui/material";
import ModuleBase from "components/ModuleBase";
import { useViewer } from "viewer";
import { ModuleId } from "utils/getModuleId";

function Overview() {
  const [viewer_, setViewer] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const url = window.location.href;
  const parts = url.split("/");
  const module_id = parts[parts.length - 1];

  const { viewer } = useViewer();
  const { sideBarRoutes } = useViewer();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${baseUrl}/viewer-modules/${ModuleId(sideBarRoutes)}?`)
      .then((res) => {
        setIsLoading(false);
        setViewer(res.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [sideBarRoutes]);

  const [isTextScrollable, setIsTextScrollable] = useState(false);
  const textContainerRef = useRef(null);

  useEffect(() => {
    // Check if the text content overflows the container
    const container = textContainerRef.current;
    if (container) {
      setIsTextScrollable(container.scrollHeight > container.clientHeight);
    }
  }, [viewer_?.overviewContent]);

  return (
    <ModuleBase
      component={
        isLoading ? (
          <>
            <center>{/* <LinearProgress color="secondary" /> */}</center>{" "}
          </>
        ) : (
          <div
            style={{
              backgroundColor: `#${viewer_.viewerFrameBackgroundColor}`,
              overflow: "auto",
              scrollbarColor: "rgba(255, 255, 255, 0.2) transparent",
              scrollbarWidth: "thin",
            }}
            className="h-full w-full rounded-xl sm:overflow-hidden overflow-y-scroll custome_Scroll_save_bottom_overview "
          >
            <div
              ref={textContainerRef}
              className={`h-full w-full rounded-xl ${
                isTextScrollable ? "" : "flex" // Apply 'flex' class conditionally
              } flex-col 2xl:justify-center items-center space-y-5 2xl:space-y-10 p-5 lg:p-10`}
            >
              <center>
                <img
                  src={viewer_?.logoUrl}
                  alt=""
                  style={{
                    width: "300px",
                    "@media (min-width: 2xl)": {
                      width: "400px",
                    },
                    borderRadius: viewer_.useRoundedCorners ? "8px" : "0px",
                    border:
                      viewer_?.logoUrl && viewer_.useLogoBox
                        ? `4px solid ${viewer_.colorOfBox || "#FFFFFF"}`
                        : "none",
                  }}
                />
              </center>
              <div className="text-center">
                <h1
                  style={{ color: viewer_.mapTitle || "#FFFFFF" }}
                  className="uppercase tracking-[0.15rem] font-semibold NunitoFont text-xl lg:text-3xl"
                >
                  {viewer_?.overviewHeading}
                </h1>
                <h2
                  style={{ color: viewer_.mapTitle || "#FFFFFF" }}
                  className="tracking-[0.15rem] font-semibold NunitoFont text-base lg:text-lg italic mt-4"
                >
                  {viewer_?.overviewSubheading}
                </h2>
              </div>
              {viewer_?.overviewContent?.split("\n\n")?.map((paragraph) => (
                <p
                  style={{ color: viewer_.mapTitle || "#FFFFFF",  }}
                  className="tracking-wider overview-padding font-normal NunitoFont text-base text-center"
                >
                  {paragraph.split("\n").map((line, i) => (
                    <React.Fragment key={i}>
                      <span>{line}</span>
                      <br />
                    </React.Fragment>
                  ))}
                </p>
              ))}
              {/* <div>
                <p
                  style={{ color: viewer_.mapTitle || "#FFFFFF" }}
                  className="tracking-wider font-normal NunitoFont text-base text-center 2xl:hidden"
                >
                  {viewer_?.overviewContent}
                </p>
                <br />
              </div> */}
            </div>
          </div>
        )
      }
    />
  );
}

export default Overview;
