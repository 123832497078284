/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink, useParams } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Custome Image Component
import ImageInfo from "components/ImageInfo";

// Material Dashboard 2 PRO React examples
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavList from "examples/Sidenav/SidenavList";
import SidenavItem from "examples/Sidenav/SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";

// Custom styles for DashboardNavbar
import { navbarDesktopMenu } from "examples/Navbars/DashboardNavbar/styles";

import { ChevronLeft, ChevronRight, FullScreen } from "akar-icons";
// import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setTransparentSidenav,
  setWhiteSidenav,
  setTransparentNavbar,
  setMiniSidenav,
} from "context";
import { query } from "api";
import { endpoints } from "api";
import {
  Box,
  Grid,
  LinearProgress,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Skeleton,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  outline: "none",
  boxShadow: 24,
  p: 4,
};
function Sidenav({
  setOpenShareModal,
  color,
  viewer,
  routes,
  brand,
  isLoading,
  mainDashboard,
  ...rest
}) {
  const fontColor = viewer?.fontColor;

  // const [navbarType, setNavbarType] = useState();
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, fixedNavbar, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(2)[0];
  const itemParentName = pathname.split("/").slice(2).join("/");
  // const [openMenu, setOpenMenu] = useState(false);

  // const route = useLocation().pathname.split("/").slice(1);

  // useEffect(() => {
  //   if (Object.keys(viewer).length > 0) {
  //     setFetchingModules(true);

  //   }
  // }, [viewer]);

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  // const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      // setNavbarType("sticky");
    } else {
      // setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const [isOpen, setIsOpen] = useState("block");

  const handleMiniSidenav = () => {
    if (isOpen === "block") {
      setIsOpen("none");
    } else {
      setIsOpen("block");
    }
    if (
      !document.querySelector(".hideSideNavClick").classList.contains("css-srq5h2-MuiDrawer-docked")
    ) {
      setIsOpen("block");
    } else {
      setIsOpen("none");
    }
    setMiniSidenav(dispatch, !miniSidenav);
    let sideBar = document.querySelector(".hideSideNavClick");

    if (window.innerWidth > 1200) {
      // document.querySelector(".hideSideNavClick").style.display = "block";
    }

    if (window.innerWidth > 991 && window.innerWidth < 1200) {
      if (sideBar.classList.contains("css-8jmr5u-MuiDrawer-docked")) {
        sideBar.style.display = "none";
      } else {
        sideBar.style.display = "block";
      }
      if (isOpen === "block") {
        setIsOpen("none");
      } else {
        setIsOpen("block");
      }
    }

    if (window.innerWidth > 992) {
      if (isOpen === "block") {
        setIsOpen("none");
      } else {
        setIsOpen("block");
      }
      if (document.querySelector(".MuiBox-root.css-16tpajl")) {
        // let file = document.querySelector(".MuiBox-root.css-16tpajl");
        if (sideBar.classList.contains("css-srq5h2-MuiDrawer-docked")) {
          // file.setAttribute("id", "");
        } else {
          // file.setAttribute("id", "somethingWentWrong");
        }
      }
      if (document.querySelector(".MuiBox-root.css-3ct2sb")) {
        // let file = document.querySelector(".MuiBox-root.css-3ct2sb");
        if (sideBar.classList.contains("css-srq5h2-MuiDrawer-docked")) {
          // file.setAttribute("id", "");
        } else {
          // file.setAttribute("id", "somethingWentWrong");
        }
      }
    }
  };
  // const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  // const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  // const handleCloseMenu = () => setOpenMenu(false);
  const closeMiniSidenav = () => {
    setIsOpen("none");
    setMiniSidenav(dispatch, true);
    let sideBar = document.querySelector(".hideSideNavClick");

    if (window.innerWidth > 991 && window.innerWidth < 1200) {
      if (sideBar.classList.contains("css-8jmr5u-MuiDrawer-docked")) {
        sideBar.style.display = "none";
      } else {
        sideBar.style.display = "none";
      }
    }
  };

  useEffect(() => {
    if (viewer.slug) {
      if (!viewer.showMenu) {
        closeMiniSidenav();
      }
    }
  }, [viewer]);

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, []);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      if (viewer.showMenu) {
        setMiniSidenav(dispatch, window.innerWidth < 1200);
        setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
        setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
      }
    }

    /**
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    );

    return template;
  };
  let currentLink = window.location.href;

  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(({ name, collapse, route, href, key }) => {
      let returnValue;

      if (collapse) {
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          ></Link>
        ) : (
          <SidenavItem key={key} route={route} color={`#${fontColor}`} name={name} active={route === itemParentName} />
        );
      }

      //! This is the text where the collapse generate (nayem)
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, icon, name, title, collapse, noCollapse, key, href, route, isReload }) => {
      let returnValue;

      if (type === "collapse") {
        if (href) {
          returnValue = (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          );
        } else if (noCollapse && route) {
          const navOnClick = () => {
            setOpenCollapse(false);
            if (isReload) {
              if (document.querySelector(".homeGalleryButton")) {
                document.querySelector(".homeGalleryButton").click();
              }
            }
          };
          returnValue = (
            <NavLink onClick={() => navOnClick(route)} to={route} key={key}>
              <SidenavCollapse
                // this is for normal ones
                name={name}
                route={route.split("/")[0]}
                fontColor={viewer?.fontColor}
                noCollapse={noCollapse}
                active={route === itemParentName}
                onClick={() => (openCollapse === key ? false : setOpenCollapse(key))}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else {
          returnValue = (
            <SidenavCollapse
              // the ones with children
              key={key}
              name={name}
              route={route.split("/")[0]}
              icon={icon}
              fontColor={viewer?.fontColor}
              active={route === itemParentName}
              open={openCollapse === key}
              onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        }
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      } else if (type === "imgWithInfo") {
        // renderImageInfo = <ImageInfo />;
        returnValue = "";
      }
      return returnValue;
    }
  );

  // Styles for the navbar icons
  // const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
  //   color: () => {
  //     let colorValue = darkMode ? white.main : dark.main;
  //     return colorValue;
  //   },
  // });

  //Check if user first time
  // const localData = localStorage.getItem("firstTime") || false;
  // localData == false ? handleMiniSidenav() : localStorage.setItem("firstTime", "true");
  const toggleFullScreen = () => {
    localStorage.setItem("isNavBarOpen", false);
    if (!viewer.showMenu) {
      setMiniSidenav(dispatch, true);
    }
    if (
      !document.fullscreenElement && // alternative standard method
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }

    setTimeout(() => {
      localStorage.setItem("isNavBarOpen", true);
    }, 1000);
  };

  const [bgColor, setBgColor] = useState(`#${viewer?.windowColor}`);
  useEffect(() => {
    setBgColor(`#${viewer?.windowColor}`);
  }, [viewer?.windowColor]);

  const loadingNestable = (
    <div className="rounded-md bg-white h-[65px] shadow-md flex gap-x-2 items-center p-3">
      <Skeleton
        style={{ backgroundColor: `#${viewer?.menuColor}` }}
        className="w-[20px] rounded-full"
      />
      <Skeleton
        style={{ backgroundColor: `#${viewer?.menuColor}` }}
        variant="rectangular"
        className="w-[180px] h-[20px] rounded-lg"
      />
    </div>
  );

  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const [pageHeight, setPageHeight] = useState(window.innerHeight);

  useEffect(() => {
    // Update dimensions when the window is resized
    const handleResize = () => {
      setPageWidth(window.innerWidth);
      setPageHeight(window.innerHeight);
    };

    // Add an event listener to listen for window resize events
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let sideHeight = {};

  // show socials on - show agent on - logo off (passed)
  if (!viewer.showSocials && !viewer.showAgentInfo && !brand) {
    if (pageWidth <= 857) {
      sideHeight = { height: "63vh" };
    } else {
      sideHeight = { height: "67vh" };
    }
    // show socials on - show agent off - logo on (passed)
  } else if (!viewer.showSocials && viewer.showAgentInfo && brand) {
    if (pageWidth <= 857) {
      sideHeight = { height: "55vh" };
    } else {
      sideHeight = { height: "70vh" };
    }
    // show socials on - show agent off - logo off (passed)
  } else if (!viewer.showSocials && viewer.showAgentInfo && !brand) {
    if (pageWidth <= 857) {
      sideHeight = { height: "76vh" };
    } else {
      sideHeight = { height: "80vh" };
    }
    // show socials off - show agent off - logo on (passed)
  } else if (viewer.showSocials && viewer.showAgentInfo && brand) {
    if (pageWidth <= 857) {
      sideHeight = { height: "71vh" };
    } else {
      sideHeight = { height: "75vh" };
    }
    // show socials off - show agent off - logo off (passed)
  } else if (viewer.showSocials && viewer.showAgentInfo && !brand) {
    if (pageWidth <= 857) {
      sideHeight = { height: "81vh" };
    } else {
      sideHeight = { height: "85vh" };
    }
  } else if (viewer.showSocials && !viewer.showAgentInfo && !brand) {
    if (pageWidth <= 857) {
      sideHeight = { height: "78vh" };
    } else {
      sideHeight = { height: "70vh" };
    }
  } else if (viewer.showSocials && !viewer.showAgentInfo && brand) {
    if (pageWidth <= 857) {
      sideHeight = { height: "46vh" };
    } else {
      sideHeight = { height: "60vh" };
    }

    // =============================================
  } // show socials on - show agent on - logo off (passed)
  if (!viewer.showSocials && !viewer.showAgentInfo && !brand && viewer?.removeOneviewerLogo) {
    if (pageWidth <= 857) {
      sideHeight = { height: "72vh" };
    } else {
      sideHeight = { height: "75vh" };
    }
    // show socials on - show agent off - logo on (passed)
  } else if (!viewer.showSocials && viewer.showAgentInfo && brand && viewer?.removeOneviewerLogo) {
    if (pageWidth <= 857) {
      sideHeight = { height: "75vh" };
    } else {
      sideHeight = { height: "78vh" };
    }
    // show socials on - show agent off - logo off (passed)
  } else if (!viewer.showSocials && viewer.showAgentInfo && !brand && viewer?.removeOneviewerLogo) {
    if (pageWidth <= 857) {
      sideHeight = { height: "85vh" };
    } else {
      sideHeight = { height: "88vh" };
    }
    // show socials off - show agent off - logo on (passed)
  } else if (viewer.showSocials && viewer.showAgentInfo && brand && viewer?.removeOneviewerLogo) {
    if (pageWidth <= 857) {
      sideHeight = { height: "80vh" };
    } else {
      sideHeight = { height: "83vh" };
    }
    // show socials off - show agent off - logo off (passed)
  } else if (viewer.showSocials && viewer.showAgentInfo && !brand && viewer?.removeOneviewerLogo) {
    if (pageWidth <= 857) {
      sideHeight = { height: "90vh" };
    } else {
      sideHeight = { height: "93vh" };
    }
  } else if (viewer.showSocials && !viewer.showAgentInfo && !brand && viewer?.removeOneviewerLogo) {
    if (pageWidth <= 857) {
      sideHeight = { height: "75vh" };
    } else {
      sideHeight = { height: "78vh" };
    }
  } else if (viewer.showSocials && !viewer.showAgentInfo && brand && viewer?.removeOneviewerLogo) {
    if (pageWidth <= 857) {
      sideHeight = { height: "65vh" };
    } else {
      sideHeight = { height: "68vh" };
    }
  }

  return (
    <>
      {/* <div id="hamburger">
        <span>
          <TextAlignJustified strokeWidth={2} size={18} />
          <img src="/images/humberger.png" style={{ width: "18px" }} alt="This is a image" />
        </span>
      </div> */}
      {/* <IconButton
        id="hamburger"
        sx={navbarDesktopMenu}
        onClick={handleMiniSidenav}
        size="small"
        disableRipple
      >
        <img src="/images/humberger.png" style={{ width: "18px" }} alt="This is a image" />
      </IconButton> */}

      {viewer.showMenu && (
        <IconButton
          id="hamburger"
          // sx={navbarDesktopMenu}
          onClick={handleMiniSidenav}
          size="small"
          disableRipple
          style={{ backgroundColor: `${bgColor}` }}
          className={`fixed -left-[0.1rem] top-[45%]`}
        >
          <div className="h-6 w-6">
            {viewer?.viewerControlsColor ? (
              <ChevronRight
                color={`#${viewer?.viewerControlsColor}`}
                strokeWidth={3}
                className="text-[#2f2e33] ml-[0.40rem] text-[30px] 2xl:text-[35px]"
              />
            ) : (
              <ChevronRight
                strokeWidth={3}
                color={`#${viewer?.viewerControlsColor}`}
                className="text-[#2f2e33] ml-[0.40rem] text-[30px] 2xl:text-[35px]"
              />
            )}
          </div>
        </IconButton>
      )}
      <IconButton
        id="fullscreen"
        sx={navbarDesktopMenu}
        onClick={toggleFullScreen}
        size="small"
        disableRipple
        style={{ backgroundColor: `${bgColor}` }}
        className={` rounded-full mr-1 mt-1`}
      >
        {viewer?.viewerControlsColor ? (
          <FullScreen
            strokeWidth={3}
            color={`#${viewer?.viewerControlsColor}`}
            className=""
            size={20}
          />
        ) : (
          <FullScreen strokeWidth={3} className="text-[#3a3a41]" size={20} />
        )}
        {/* <img
          src="/images/full-screen-button-1.png"
          style={{ width: "18px" }}
          alt="This is a image"
        /> */}
        {/* <Icon fontSize="medium" sx={iconsStyle}>
          {miniSidenav ? "menu_open" : "menu"}
        </Icon> */}
      </IconButton>

      <IconButton
        id="sharebuttonnew"
        // sx={navbarDesktopMenu}
        onClick={() => {
          setOpenShareModal(true);
        }}
        size="small"
        disableRipple
        style={{ backgroundColor: `${bgColor}` }}
        className="rounded-full mr-1 mt-1"
      >
        {viewer?.viewerControlsColor ? (
          <svg
            style={{ fill: `#${viewer?.viewerControlsColor}` }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            width="19"
            height="20"
          >
            <path d="M448 127.1C448 181 405 223.1 352 223.1C326.1 223.1 302.6 213.8 285.4 197.1L191.3 244.1C191.8 248 191.1 251.1 191.1 256C191.1 260 191.8 263.1 191.3 267.9L285.4 314.9C302.6 298.2 326.1 288 352 288C405 288 448 330.1 448 384C448 437 405 480 352 480C298.1 480 256 437 256 384C256 379.1 256.2 376 256.7 372.1L162.6 325.1C145.4 341.8 121.9 352 96 352C42.98 352 0 309 0 256C0 202.1 42.98 160 96 160C121.9 160 145.4 170.2 162.6 186.9L256.7 139.9C256.2 135.1 256 132 256 128C256 74.98 298.1 32 352 32C405 32 448 74.98 448 128L448 127.1zM95.1 287.1C113.7 287.1 127.1 273.7 127.1 255.1C127.1 238.3 113.7 223.1 95.1 223.1C78.33 223.1 63.1 238.3 63.1 255.1C63.1 273.7 78.33 287.1 95.1 287.1zM352 95.1C334.3 95.1 320 110.3 320 127.1C320 145.7 334.3 159.1 352 159.1C369.7 159.1 384 145.7 384 127.1C384 110.3 369.7 95.1 352 95.1zM352 416C369.7 416 384 401.7 384 384C384 366.3 369.7 352 352 352C334.3 352 320 366.3 320 384C320 401.7 334.3 416 352 416z" />
          </svg>
        ) : (
          <svg
            style={{ fill: "#ffff" }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            width="19"
            height="20"
          >
            <path d="M448 127.1C448 181 405 223.1 352 223.1C326.1 223.1 302.6 213.8 285.4 197.1L191.3 244.1C191.8 248 191.1 251.1 191.1 256C191.1 260 191.8 263.1 191.3 267.9L285.4 314.9C302.6 298.2 326.1 288 352 288C405 288 448 330.1 448 384C448 437 405 480 352 480C298.1 480 256 437 256 384C256 379.1 256.2 376 256.7 372.1L162.6 325.1C145.4 341.8 121.9 352 96 352C42.98 352 0 309 0 256C0 202.1 42.98 160 96 160C121.9 160 145.4 170.2 162.6 186.9L256.7 139.9C256.2 135.1 256 132 256 128C256 74.98 298.1 32 352 32C405 32 448 74.98 448 128L448 127.1zM95.1 287.1C113.7 287.1 127.1 273.7 127.1 255.1C127.1 238.3 113.7 223.1 95.1 223.1C78.33 223.1 63.1 238.3 63.1 255.1C63.1 273.7 78.33 287.1 95.1 287.1zM352 95.1C334.3 95.1 320 110.3 320 127.1C320 145.7 334.3 159.1 352 159.1C369.7 159.1 384 145.7 384 127.1C384 110.3 369.7 95.1 352 95.1zM352 416C369.7 416 384 401.7 384 384C384 366.3 369.7 352 352 352C334.3 352 320 366.3 320 384C320 401.7 334.3 416 352 416z" />
          </svg>
        )}

        {/* <Icon fontSize="medium" sx={iconsStyle}>
          {miniSidenav ? "menu_open" : "menu"}
        </Icon> */}
      </IconButton>

      <SidenavRoot
        {...rest}
        className="hideSideNavClick"
        style={{ display: isOpen }}
        variant="permanent"
        ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
      >
        {/* {isLoading ? <LinearProgress color="secondary" /> : null} */}
        <>
          {" "}
          <IconButton
            id="hamburger"
            // sx={navbarDesktopMenu}
            onClick={handleMiniSidenav}
            size="small"
            disableRipple
            style={{ backgroundColor: `${bgColor}` }}
            className={`fixed -right-[0.95rem] lg:-right-[1.0rem] top-[45%]`}
            // className="fixed -right-[0.95rem] lg:-right-[1.0rem] top-[45%] bg-[#f0f2f5]"
          >
            <div className="h-6 w-6">
              {viewer?.viewerControlsColor ? (
                <ChevronLeft
                  color={`#${viewer?.viewerControlsColor}`}
                  strokeWidth={3}
                  className="text-[#2f2e33] -ml-[0.35rem] lg:mt-0 mt-[0.025rem] lg:-ml-1 text-[30px] 2xl:text-[35px]"
                />
              ) : (
                <ChevronLeft
                  strokeWidth={3}
                  className="text-[#2f2e33] -ml-[0.35rem] lg:mt-0 mt-[0.025rem] lg:-ml-1 text-[30px] 2xl:text-[35px]"
                />
              )}
            </div>
          </IconButton>
          <MDBox className="galleryLayoutScroll overflowHiddenImportant">
            <MDBox pt={3} pb={1} px={4} textAlign="center">
              <MDBox
                component={NavLink}
                to={viewer?.baseModule ? viewer?.baseModule : mainDashboard}
                display="flex"
                alignItems="center"
                onClick={() => setOpenCollapse(false)}
                className="flex items-center justify-center"
              >
                <div className="flex items-center justify-center  w-full">
                  {brand && !isLoading ? (
                    <img src={brand} style={{ height: "76px" }} alt="Brand" />
                  ) : (
                    // <MDBox nayem="First" component="img" alt="Brand" width="100%" /><>
                    <>{/* <br /> */}</>
                  )}
                </div>
                {/* {brand && (
                <MDBox
                  nayem="First"
                  component="img"
                  src={brand}
                  alt="Brand"
                  width="100%"
                />
              )} */}
              </MDBox>
            </MDBox>
            {brand && !isLoading && (
              <center>
                <hr
                  style={{
                    marginTop: "10px",
                    marginBottom: "7px",
                    color: `#${viewer?.fontColor}`,
                    width: "80%",
                    opacity: "0.2",
                  }}
                />{" "}
              </center>
            )}
            <List className={`${ !viewer.showSocials && !viewer.showAgentInfo ? 'custome_Scroll_save_bottom':'' }`}>{renderRoutes} </List>
            <div className="custome_Scroll_save_bottom_anim"></div>

            <List className="bottom-list">
              {/* {renderImageInfo}{" "} */}
              {viewer.removeOneviewerLogo && (
                <>
                  <br />
                  <br />
                </>
              )}

              {(!isLoading && !viewer.showAgentInfo) || !viewer.showSocials ? (
                <>
                  <center>
                    <hr
                      style={{
                        marginTop: "10px",
                        marginBottom: "15px",
                        color: `#${viewer?.fontColor}`,
                        width: "80%",
                        opacity: "0.2",
                      }}
                    />{" "}
                  </center>
                </>
              ) : (
                <></>
              )}

              <ImageInfo fontColor={fontColor} />

              {!isLoading && !viewer?.removeOneviewerLogo && (
                <>
                  <center>
                    <hr
                      style={{
                        marginTop: "10px",
                        marginBottom: "15px",
                        color: `#${viewer?.fontColor}`,
                        width: "80%",
                        opacity: "0.2",
                        lineHeight: "30px",
                      }}
                    />{" "}
                  </center>
                  <MDBox
                    to="/"
                    display="flex"
                    alignItems="center"
                    marginBottom="18px"
                    justifyContent="center"
                  >
                    <Link href="https://www.viewlio.com/" target="_blank">
                      <MDBox
                        component="img"
                        src="/images/logo.png"
                        alt="Brand"
                        width="150px"
                        style={{ cursor: "pointer", marginBottom: "1rem" }}
                      />
                    </Link>
                  </MDBox>
                </>
              )}
            </List>
          </MDBox>
        </>
      </SidenavRoot>
    </>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  viewer: PropTypes.string,
  isLoading: PropTypes.string,
  mainDashboard: PropTypes.string,
  setOpenShareModal: PropTypes.func,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
