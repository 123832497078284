/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavItem
import { item, itemContent, itemArrow } from "examples/Sidenav/styles/sidenavItem";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";
import { useViewer } from "viewer";
import { NavLink } from "react-router-dom";
import {
  collapseItem,
  collapseIconBox,
  collapseText,
  collapseArrow,
} from "examples/Sidenav/styles/sidenavCollapse";
function SidenavItem({ route, key, color, name, active, nested, children, open, ...rest }) {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const { viewer } = useViewer();

  return (
    <>
      <ListItem component="li">
        <MDBox
          {...rest}
          style={{ color: color }}
          sx={(theme) =>
            collapseItem(theme, { active, transparentSidenav, whiteSidenav, darkMode })
          }
          nayem="Value"
          className="removeLatterSubMenu NunitoFont makeThemBold"
        >
          <NavLink to={route} key={key}>
            <ListItemText primary={name} />
            {children && (
              <Icon
                component="i"
                sx={(theme) =>
                  itemArrow(theme, {
                    open,
                    miniSidenav,
                    transparentSidenav,
                    whiteSidenav,
                    darkMode,
                  })
                }
              >
                expand_less
              </Icon>
            )}
          </NavLink>
        </MDBox>
      </ListItem>
    </>
  );
}

// Setting default values for the props of SidenavItem
SidenavItem.defaultProps = {
  color: "info",
  active: false,
  nested: false,
  children: false,
  open: false,
};

// Typechecking props for the SidenavItem
SidenavItem.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  name: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  nested: PropTypes.bool,
  children: PropTypes.node,
  open: PropTypes.bool,
};

export default SidenavItem;
