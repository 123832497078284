/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React, { useEffect, useState } from "react";
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
// import "@react-pdf-viewer/core/lib/styles/index.css";
// import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import "pdf.css";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { useViewer } from "viewer";
import envConfigs from "envConfigs";
//
const PdfViewer = ({ pdfUrl }) => {
  const [convertedUrl, setConverted] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

  const transformToolbarSlot = (slot) => {
    return {
      ...slot,
      Open: () => <></>,
      // DownloadMenuItem: () => <></>,
      OpenMenuItem: () => <></>,
      EnterFullScreen: () => <></>,
      EnterFullScreenMenuItem: () => <></>,
      SwitchTheme: () => <></>,
      SwitchThemeMenuItem: () => <></>,
    };
  };

  useEffect(() => {
    const convertPdfToBase64 = async () => {
      setIsLoading(true);
      try {
        // Fetch the PDF file as binary content
        const response = await axios.get(pdfUrl, {
          responseType: "arraybuffer",
        });

        // Convert the binary content to base64
        const arrayBuffer = response.data;
        const uint8Array = new Uint8Array(arrayBuffer);
        const pdfBase64 = bufferToBase64(uint8Array);

        const pdfstr = await fetch(`data:application/pdf;base64,${pdfBase64}`);
        // const blob = base64toBlob(pdfBase64);
        const blobFromFetch = await pdfstr.blob();

        var blob = new Blob([blobFromFetch], { type: "application/pdf" });

        const blobUrl = URL.createObjectURL(blob);
        setConverted(blobUrl);
        console.log(blobUrl);
        setIsLoading(false);
      } catch (error) {
        console.error(`Error converting PDF to base64: ${error.message}`);
        setIsLoading(false);
      }
    };

    convertPdfToBase64();
  }, []);

  const bufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;

    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    return window.btoa(binary);
  };

  const { viewer } = useViewer();

  // The API version "3.4.120" does not match the Worker version "3.11.174".
  // const pdfVersion = "3.11.174";
  // if (envConfigs === "local") {
  const  pdfVersion = "3.4.120";
  // }

  return (
    <div>
      <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfVersion}/build/pdf.worker.min.js`}>
        {convertedUrl ? (
          <div
            className="rpv-core__viewer"
            style={{
              border: "1px rgb(238, 238, 238)",
              display: "flex",
              flexDirection: "column",
              height: "98vh",
            }}
          >
            <div
              style={{
                alignItems: "center",
                backgroundColor: "#313639",
                color: '#ffffff',
                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                display: "flex",
                padding: "0.25rem",
              }}
            >
              <Toolbar>{renderDefaultToolbar(transformToolbarSlot)}</Toolbar>
            </div>
            <div
              style={{
                flex: 1,
                overflow: "hidden",
              }}
            >
              <Viewer
                fileUrl={convertedUrl}
                plugins={[toolbarPluginInstance]}
                defaultScale={SpecialZoomLevel.PageFit}
              />
            </div>
          </div>
        ) : (
          <>
            {isLoading ? (
              <div className="h-full w-full rounded-xl sm:overflow-hidden flex flex-col 2xl:justify-center items-center space-y-5 2xl:space-y-10 p-5 lg:p-10 overflow-y-scroll comingsoontext">
                <>
                  <div className="text-center">
                    <CircularProgress
                      style={{
                        color: viewer?.windowColor ? `#${viewer?.windowColor}` : "white",
                      }}
                    />
                  </div>
                </>
              </div>
            ) : (
              ""
            )}
          </>
        )}
      </Worker>
    </div>
  );
};

export default PdfViewer;
