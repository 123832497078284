/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Logo from "./logo.png";
import { query } from "api";

function Overview() {
  return (
    <DashboardLayout>
      <div className="h-full w-full rounded-xl sm:overflow-hidden bg-[#3b3c41] flex flex-col 2xl:justify-center items-center space-y-5 2xl:space-y-10 p-5 lg:p-10 overflow-y-scroll">
        <img src="/images/logo.png" alt="" className="w-[300px] 2xl:w-[400px]" />

        <div className="text-center">
          <p className="text-white uppercase ">
            Click on the items on the sidebar to view different modules
          </p>
        </div>

        {/* {text.split("\n\n").map((paragraph) => (
          <p className="text-white tracking-wider font-normal NunitoFont text-base text-center hidden 2xl:block">
            {paragraph.split("\n").reduce((total, line) => [<span>{total}</span>, <br />, line])}
          </p>
        ))} */}

        <div></div>
      </div>
    </DashboardLayout>
  );
}

export default Overview;
