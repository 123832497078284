/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect } from "react";

// react-router components
import { Routes, Route, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React component

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// RTL plugins

// Material Dashboard 2 PRO React routes

// Material Dashboard 2 PRO React contexts

// Images
import "./App.css";
import Viewer from "viewer";
import NotFoundPage from "layouts/pages/not-found";

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    // eslint-disable-next-line no-constant-condition
    <ThemeProvider theme={false ? themeDark : theme}>
      <CssBaseline />
      <Routes>
        <Route exact path="/not-found" element={<NotFoundPage />}></Route>
        <Route exact path="/" element={<NotFoundPage />}></Route>
        <Route path=":viewerName/*" element={<Viewer />}></Route>
      </Routes>
    </ThemeProvider>
  );
}
