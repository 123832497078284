/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseText,
  collapseArrow,
} from "examples/Sidenav/styles/sidenavCollapse";
import {
  ArrowCycle,
  Calendar,
  File,
  CodepenFill,
  Image,
  Info,
  InstagramFill,
  Location,
  PanelSplit,
  Grid,
  Video,
  Globe
} from "akar-icons";
// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { useViewer } from "viewer";

function SidenavCollapse({ name, route, children, active, noCollapse, open, fontColor, ...rest }) {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const { viewer } = useViewer();

  const size = 18;
  let icon = "";
  if (route === "virtual-tour") {
    icon = (
      <ArrowCycle
        color={`#${viewer?.fontColor}` || "white"}
        className="iconPositionChangeSN"
        strokeWidth={2}
        size={size}
      />
    );
  } else if (route === "photo-gallery") {
    icon = (
      <Image
        color={`#${viewer?.fontColor}` || "white"}
        className="iconPositionChangeSN"
        size={size}
      />
    );
  } else if (route === "video-gallery") {
    icon = (
      <Video
        strokeWidth={2}
        className="iconPositionChangeSN"
        color={`#${viewer?.fontColor}` || "white"}
        size={size}
      />
    );
  } else if (route === "3d-model") {
    icon = (
      <CodepenFill
        strokeWidth={2}
        className="iconPositionChangeSN"
        color={`#${viewer?.fontColor}` || "white"}
        size={size}
      />
    );
  } else if (route === "website") {
    icon = (
      <Globe
        strokeWidth={2}
        className="iconPositionChangeSN"
        color={`#${viewer?.fontColor}` || "white"}
        size={size}
      />
    );
  } else if (route === "virtual-staging") {
    icon = (
      <Grid
        strokeWidth={2}
        className="iconPositionChangeSN"
        color={`#${viewer?.fontColor}` || "white"}
        size={size}
      />
    );
  } else if (route === "sales-deck") {
    icon = (
      <File
        strokeWidth={2}
        className="iconPositionChangeSN"
        color={`#${viewer?.fontColor}` || "white"}
        size={size}
      />
    );
  } else if (route === "contact-form") {
    icon = (
      <Calendar
        strokeWidth={2}
        className="iconPositionChangeSN"
        color={`#${viewer?.fontColor}` || "white"}
        size={size}
      />
    );
  } else if (route === "instagram") {
    icon = (
      <InstagramFill
        strokeWidth={2}
        className="iconPositionChangeSN"
        color={`#${viewer?.fontColor}` || "white"}
        size={size}
      />
    );
  } else if (route === "overview") {
    icon = (
      <Info
        strokeWidth={2}
        color={`#${viewer?.fontColor}` || "white"}
        className="iconPositionChangeSN"
        size={size}
      />
    );
  } else if (route === "directions") {
    icon = (
      <Location
        strokeWidth={2}
        color={`#${viewer?.fontColor}` || "white"}
        className="iconPositionChangeSN"
        size={size}
      />
    );
  } else if (route === "custom") {
    icon = (
      <Globe
        strokeWidth={2}
        color={`#${viewer?.fontColor}` || "white"}
        className="iconPositionChangeSN"
        size={size}
      />
    );
  } else if (route === "floor-plans") {
    icon = (
      <PanelSplit
        strokeWidth={2}
        color={`#${viewer?.fontColor}` || "white"}
        className="iconPositionChangeSN"
        size={size}
      />
    );
  }
  return (
    <>
      <ListItem component="li">
        <MDBox
          {...rest}
          sx={(theme) =>
            collapseItem(theme, { active, transparentSidenav, whiteSidenav, darkMode })
          }
        >
          <ListItemIcon
            sx={(theme) => collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode })}
          >
            {viewer?.useIconsForMainMenuItems ? icon : null}
          </ListItemIcon>

          <ListItemText
            primary={name}
            style={{ color: `#${fontColor}` }}
            className={`${viewer?.font?.replace(" ", "")}Font makeThemBold changeFontSizeRem`}
            sx={(theme) =>
              collapseText(theme, {
                miniSidenav,
                transparentSidenav,
                whiteSidenav,
                active,
              })
            }
          />

          <Icon
            style={{ color: `#${fontColor}` }}
            sx={(theme) =>
              collapseArrow(theme, {
                noCollapse,
                transparentSidenav,
                whiteSidenav,
                miniSidenav,
                open,
                active,
                darkMode,
              })
            }
          >
            expand_less
          </Icon>
        </MDBox>
      </ListItem>
      {children && (
        <Collapse in={open} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
  noCollapse: false,
  children: false,
  open: false,
  fontColor: "#ffffff",
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  route: PropTypes.string,
  children: PropTypes.node,
  active: PropTypes.bool,
  noCollapse: PropTypes.bool,
  open: PropTypes.bool,
};

export default SidenavCollapse;
