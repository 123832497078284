/* eslint-disable react/prop-types */
/* eslint-disable no-case-declarations */

import React, { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import SinglePhotos from "./SinglePhotos";
import MinimalPhotos from "./MinimalPhotos";
import StandardPhotos from "./StandardPhotos";

import "./gallery.css";

const Gallery = ({
  setTab,
  tab,
  setFiles,
  files,
  folderName,
  viewer_,
  setModuleId,
  moduleId,
  moduleType,
  setIsLoading,
}) => {
  const [minimalStyle, SetminimalStyle] = React.useState({ filter: "brightness(0.5)" });
  const [standardStyle, SetstandardStyle] = React.useState({ filter: "brightness(1)" });
  const [singleStyle, SetsingleStyle] = React.useState({ filter: "brightness(1)" });

  const handleChange = (value, valueBright) => {
    SetminimalStyle({
      filter: `brightness(${valueBright[0]})`,
    });
    SetstandardStyle({
      filter: `brightness(${valueBright[1]})`,
    });
    SetsingleStyle({
      filter: `brightness(${valueBright[2]})`,
    });
    setTab(value);
  };

  useEffect(() => {
    if (folderName.length === 1 && !viewer_.displayFolder) {
      setTab("standard");
      setModuleId(folderName[0]?.moduleId);
    } else {
      setTab("minimal");
    }
  }, [viewer_, folderName]);

  function handleFile(folderName, url, itemIndex) {
    handleChange("single", [1, 1, 0.5]);
    localStorage.setItem("folderName", folderName);
    localStorage.setItem("url", url);
    localStorage.setItem("itemIndex", itemIndex);
  }

  function handleFoldeFile(folderName, url, isPortrait = false) {
    handleChange("single", [1, 1, 0.5]);
    localStorage.setItem("folderName", folderName);
    localStorage.setItem("url", url);
    isPortrait
      ? localStorage.setItem("isPortrait", true)
      : localStorage.removeItem("isPortrait") || false;
  }

  useEffect(() => {
    if (folderName.length === 1 && files.length == 1 && !viewer_.displayFolder) {
      setTab("single");
      // setModuleId(folderName[0]?.moduleId);
      if (files[0].isPortrait) {
        handleFoldeFile(files[0].type, files[0].url, files[0].isPortrait);
      } else {
        handleFile(files[0].type, files[0].url, 0);
      }
    }
  }, [files]);

  const getGalleryLayout = () => {
    switch (tab) {
      case "minimal":
        return (
          <MinimalPhotos
            control={handleChange}
            setFiles={setFiles}
            setModuleId={setModuleId}
            photos={folderName}
            viewer_={viewer_}
          />
        );
      case "standard":
        return (
          <>
            {" "}
            <StandardPhotos
              control={handleChange}
              moduleId={moduleId}
              files={files}
              setFiles={setFiles}
              moduleType={moduleType}
              setIsLoading={setIsLoading}
              viewer_={viewer_}
            />{" "}
          </>
        );
      case "single":
        let singleType = localStorage.getItem("folderName") || false;
        let singleUrl = localStorage.getItem("url") || false;
        let isPortrait = localStorage.getItem("isPortrait") || false;
        let itemIndex = localStorage.getItem("itemIndex") || false;
        let singleFileterData = files;
        if (singleType != false) {
          singleFileterData = files.filter((value) => {
            return value.type == singleType;
          });
        }

        if (singleUrl != false) {
          localStorage.removeItem("url");
          let defaultPhotoObj = { type: singleType, url: singleUrl };
          isPortrait ? (defaultPhotoObj.isPortrait = true) : "";
          return (
            <SinglePhotos
              defaultPhoto={defaultPhotoObj}
              photos={singleFileterData}
              itemIndex={itemIndex}
              viewer_={viewer_}
            />
          );
        } else {
          return (
            <SinglePhotos photos={singleFileterData} itemIndex={itemIndex} viewer_={viewer_} />
          );
        }

      default:
        break;
    }
  };

  const getValueIconname = () => {
    switch (tab) {
      case "minimal":
        return (
          <div
            className="iconButtonGroup mobileheightzero"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              opacity: "0",
              visibility: "hidden",
              margintop: "-55px",
            }}
          >
            {folderName.length == 1 ? (
              <>
                {viewer_.displayFolder ? (
                  <>
                    <IconButton
                      onClick={() => {
                        handleChange("minimal", [0.5, 1, 1]);
                      }}
                      style={{ color: "red" }}
                    >
                      <img src="/images/grid4.png" style={minimalStyle} className="iconbuttonDG" />
                    </IconButton>
                  </>
                ) : null}
              </>
            ) : null}
            {folderName.length > 1 ? (
              <>
                <IconButton
                  onClick={() => {
                    handleChange("minimal", [0.5, 1, 1]);
                  }}
                  style={{ color: "red" }}
                >
                  <img src="/images/grid4.png" style={minimalStyle} className="iconbuttonDG" />
                </IconButton>
              </>
            ) : null}
            <IconButton
              style={{ zIndex: "9999" }}
              onClick={() => {
                handleChange("standard", [1, 0.5, 1]);
              }}
            >
              <img src="/images/grid9.png" style={standardStyle} className="iconbuttonDG" />
            </IconButton>{" "}
            <IconButton
              style={{ zIndex: "9999" }}
              onClick={() => {
                handleChange("single", [1, 1, 0.5]);
              }}
            >
              <img style={singleStyle} src="/images/grid1.png" className="iconbuttonDG" />
            </IconButton>{" "}
          </div>
        );
      default:
        return (
          <div
            className={
              tab == "single" ? "iconButtonGroup iconButtonGroupTopSpaceHandle" : "iconButtonGroup"
            }
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {folderName.length == 1 ? (
              <>
                {viewer_.displayFolder ? (
                  <>
                    <IconButton
                      onClick={() => {
                        handleChange("minimal", [0.5, 1, 1]);
                      }}
                      style={{ color: "red" }}
                    >
                      <img src="/images/grid4.png" style={minimalStyle} className="iconbuttonDG" />
                    </IconButton>
                  </>
                ) : null}
              </>
            ) : null}
            {folderName.length > 1 ? (
              <>
                <IconButton
                  onClick={() => {
                    handleChange("minimal", [0.5, 1, 1]);
                  }}
                  style={{ color: "red" }}
                >
                  <img src="/images/grid4.png" style={minimalStyle} className="iconbuttonDG" />
                </IconButton>
              </>
            ) : null}
            <IconButton
              style={{ zIndex: "9999" }}
              onClick={() => {
                handleChange("standard", [1, 0.5, 1]);
              }}
            >
              <img src="/images/grid9.png" style={standardStyle} className="iconbuttonDG" />
            </IconButton>{" "}
            <IconButton
              style={{ zIndex: "9999" }}
              onClick={() => {
                handleChange("single", [1, 1, 0.5]);
              }}
            >
              <img style={singleStyle} src="/images/grid1.png" className="iconbuttonDG" />
            </IconButton>{" "}
          </div>
        );
    }
  };

  return (
    <>
      {getValueIconname()}
      <div
        className={tab == "single" ? "" : "galleryLayoutScroll"}
        style={
          tab == "single"
            ? {
                width: "100%",
              }
            : {
                padding: "0px 51px 0px 47px",
              }
        }
      >
        {getGalleryLayout()}
      </div>
    </>
  );
};

export default Gallery;
