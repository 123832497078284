/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import axios from "axios";
import { baseUrl } from "api";
import MDButton from "components/MDButton";
import { Alert, CircularProgress } from "@mui/material";
import NoModule from "components/NoModule";
import { useViewer } from "viewer";
import { ModuleId } from "utils/getModuleId";

function UserInfo() {
  const [viewer, setViewer] = useState({});

  const [formFields, setFormFields] = useState({});
  const [userEmail, setUserEmail] = useState("");
  const [verifyEmail, setVerifyEmail] = useState("");
  const [fields, setFields] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isEmailRequired, setIsEmailRequired] = useState(false);
  const { sideBarRoutes } = useViewer();

  const [isLoading, setIsLoading] = useState({});
  const getModulesData = () => {
    setIsLoading(true);
    axios
      .get(`${baseUrl}/viewer-modules/${ModuleId(sideBarRoutes)}?`)
      .then((res) => {
        setIsLoading(false);
        setViewer(res.data);
        setFields(res.data.eventEnquiryFormFields);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getModulesData();
  }, [sideBarRoutes]);
  return (
    <MDBox>
      {isLoading ? (
        <center>
          <CircularProgress color="secondary" />
        </center>
      ) : null}
      <MDBox lineHeight={0}>
        <MDTypography variant="h5" style={{ textAlign: "center" }}>
          {viewer?.eventEnquiryFormTitle?.toUpperCase()}
        </MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        {isSuccess && <Alert severity="success">Submitted successfully!</Alert>}
        {isEmailRequired && <Alert severity="error">Email is required</Alert>}
        {fields?.length === 0 ? (
          <NoModule text="No fields found" />
        ) : (
          <Grid container spacing={2}>
            {fields?.map((row, i) => {
              return (
                <>
                  {row.type === "textarea" ? (
                    <>
                      {" "}
                      <Grid item xs={12} sm={12}>
                        <textarea
                          style={{
                            background: "transparent",
                            border: "1.5px solid #d2d6da",
                            width: "100%",
                            padding: "8px 2px",
                            height: "97px",
                            fontSize: "15px",
                            fontFamily: '"Roboto","Helvetica","Arial","sans-serif"',
                            padding: "4px 7px",
                            borderRadius: "5px",
                          }}
                          onChange={(e) => {
                            const fieldsData = formFields;

                            fieldsData[row.title] = e.target.value;
                            setFormFields(fieldsData);
                            setIsEmailRequired(false);
                          }}
                          className="text-[#7c819b]"
                          placeholder={row.title}
                        ></textarea>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} sm={6}>
                        {row?.type === "date" && (
                          <MDTypography className="eventEqueryDate text-[#7c819b]">
                            {row.title}
                          </MDTypography>
                        )}
                        <FormField
                          onChange={(e) => {
                            const fieldsData = formFields;
                            if (
                              viewer.eventEnquiryFormEmailCopy === "true" &&
                              row.type === "email"
                            ) {
                              setUserEmail(e.target.value);
                            }
                            if (row.type === "email") {
                              setVerifyEmail(e.target.value);
                            }
                            fieldsData[row.title] = e.target.value;
                            setFormFields(fieldsData);
                            setIsEmailRequired(false);
                          }}
                          type={row?.type}
                          label={row?.type === "date" ? "" : row.title}
                          name={row?.title?.replaceAll(" ", "-")}
                        />
                      </Grid>
                    </>
                  )}
                </>
              );
            })}
          </Grid>
        )}
      </MDBox>
      <center>
        <br />
        <MDButton
          onClick={() => {
            const hasEmailField = fields.some((item) => item.type === "email");

            if (hasEmailField) {
              if (verifyEmail == "") {
                setIsEmailRequired(true);
                return;
              }
            }
            setIsLoading(true);
            axios
              .post(`${baseUrl}/viewer-modules/send-contact-form-email/`, {
                eventEnquiryFormEmail: viewer.eventEnquiryFormEmail,
                eventEnquiryFormEmailCopy: userEmail,
                subject: viewer.name,
                formFields: formFields,
              })
              .then((res) => {
                setIsLoading(false);
                setIsSuccess(true);
                setFields([]);
                setFields(viewer?.eventEnquiryFormFields);
                getModulesData();
              })
              .catch(() => {
                setIsLoading(true);
              });
          }}
          disabled={false}
          type="button"
          variant="gradient"
          color="dark"
        >
          {isLoading ? "send" : "Submit"}
        </MDButton>
      </center>
    </MDBox>
  );
}

// typechecking props for UserInfo
UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserInfo;
