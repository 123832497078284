import React from "react";

import "./ImageInfo.css";
import Icons from "./Icons";
import { useViewer } from "viewer";

function ImageInfo() {
  const { viewer } = useViewer();

  return (
    <>
      {!viewer.showAgentInfo && (
        <div id="imageInfoTab" className="imageInfoTabstyle">
          <div className="thisImageBox">
            {viewer?.agentProfilePicture ? (
              <div
                className="thisImageBox"
                style={{ borderRadius: "50%", border: `1.8px solid #${viewer?.fontColor}` }}
              >
                <img src={`${viewer.agentProfilePicture}`} alt="img" />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className={`ThisImageBoxInfo ${viewer?.font?.replace(" ", "")}Font`}>
            <h6 style={{ color: `#${viewer?.fontColor}` }} className="agentnamefont">
              {[viewer.agentFirstName?.toUpperCase(), viewer.agentLastName?.toUpperCase()].join(
                " "
              )}
            </h6>{" "}
            <ul className={`${viewer?.font?.replace(" ", "")}Font`}>
              <li style={{ color: `#${viewer?.fontColor}` }}>{viewer.agentTitle?.toUpperCase()}</li>
              <li>
                <a
                  style={{ color: `#${viewer?.fontColor}` }}
                  href={`tel:${viewer.agentPhoneNumber || ""}`}
                >
                  {viewer.agentPhoneNumber?.toUpperCase() || ""}
                </a>
              </li>
              {viewer.agentEmailAddress ? (
                <li>
                  <a
                    className="capitalizeemailme"
                    style={{ color: `#${viewer?.fontColor}` }}
                    href={`mailto:${
                      viewer.agentEmailAddress || ""
                    }?subject=Reaching%20out%20from%20your%20Viewlio%20Gallery`}
                  >
                    SEND EMAIL
                  </a>
                </li>
              ) : (
                ""
              )}
              {viewer.websiteLink ? (
                <li>
                  <a
                    style={{ color: `#${viewer?.fontColor}` }}
                    href={`${viewer.websiteLink || ""}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    VISIT WEBSITE
                  </a>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
      )}

      <div
        id="social_media_link"
        className="social_media_link_style"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {!viewer.showSocials && (
          <Icons facebook={`${viewer.facebookAccount}`} key="IconsGeneratee" />
        )}
      </div>
    </>
  );
}

export default ImageInfo;
