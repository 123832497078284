/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-case-declarations */

import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import SinglePhotos from "./SinglePhotos";
import MinimalPhotos from "./MinimalPhotos";
import StandardPhotos from "./StandardPhotos";

import "./gallery.css";
import { baseUrl } from "api";
import axios from "axios";
import { useViewer } from "viewer";
import { LinearProgress } from "@mui/material";
import NoModule from "components/NoModule";
import { ModuleId } from "utils/getModuleId";

const Gallery = ({ moduleType }) => {
  const { viewer, sideBarRoutes } = useViewer();

  const [isLoading, setIsLoading] = useState({});
  const [folderNdame, setfolderName] = React.useState([]);
  const [files, setFiles] = React.useState([]);
  const url = window.location.href;

  let folderName = [
    {
      type: "3rdFloor",
      caption: "3rd Floor",
      thumb: "/images/FloorPlans/3rd-Floor.png",
    },
    {
      type: "11thFloor",
      caption: "11th Floor",
      thumb: "/images/FloorPlans/11th-Floor.png",
    },
    {
      type: "12thFloor",
      caption: "12th Floor",
      thumb: "/images/FloorPlans/12th-Floor.png",
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    const url = moduleType === "floor-plans" ? "floor-plans" : "photo-gallery";
    axios
      .get(`${baseUrl}/${url}-folders?viewerId=${ModuleId(sideBarRoutes)}`)
      .then((res) => {
        setIsLoading(false);
        const folderData = res?.data
          ?.sort((a, b) => a.order - b.order)
          ?.map((item) => {
            return {
              type: item.name,
              caption: item.name.toUpperCase(),
              thumb: item.thumbnail,
              files: item?.files?.map((file) => {
                return {
                  type: "File",
                  url: file,
                  thumb: file,
                };
              }),
            };
          });
        setfolderName(folderData);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [viewer, moduleType, url, sideBarRoutes]);
  const [tab, setTab] = React.useState("minimal");

  const [minimalStyle, SetminimalStyle] = React.useState({ filter: "brightness(0.5)" });
  const [standardStyle, SetstandardStyle] = React.useState({ filter: "brightness(1)" });
  const [singleStyle, SetsingleStyle] = React.useState({ filter: "brightness(1)" });

  const handleChange = (value, valueBright) => {
    SetminimalStyle({
      filter: `brightness(${valueBright[0]})`,
    });
    SetstandardStyle({
      filter: `brightness(${valueBright[1]})`,
    });
    SetsingleStyle({
      filter: `brightness(${valueBright[2]})`,
    });
    setTab(value);
  };

  const getGalleryLayout = () => {
    switch (tab) {
      case "minimal":
        return <MinimalPhotos control={handleChange} setFiles={setFiles} photos={folderName} />;
      case "standard":
        let standerType = localStorage.getItem("folderName") || false;
        let filterData = files;
        if (standerType != false) {
          filterData = files.filter((value) => {
            return value.type == standerType;
          });
        }
        return <StandardPhotos control={handleChange} photos={filterData} />;
      case "single":
        let singleType = localStorage.getItem("folderName") || false;
        let singleUrl = localStorage.getItem("url") || false;
        let isPortrait = localStorage.getItem("isPortrait") || false;
        let itemIndex = localStorage.getItem("itemIndex") || false;
        let singleFileterData = files;
        if (singleType != false) {
          singleFileterData = files.filter((value) => {
            return value.type == singleType;
          });
        }

        if (singleUrl != false) {
          localStorage.removeItem("url");
          let defaultPhotoObj = { type: singleType, url: singleUrl };
          isPortrait ? (defaultPhotoObj.isPortrait = true) : "";
          return (
            <SinglePhotos
              defaultPhoto={defaultPhotoObj}
              photos={singleFileterData}
              itemIndex={itemIndex}
            />
          );
        } else {
          return <SinglePhotos photos={singleFileterData} itemIndex={itemIndex} />;
        }

      default:
        break;
    }
  };

  //That's the comment

  const getValueIconname = () => {
    switch (tab) {
      case "minimal":
        return (
          <div
            className="iconButtonGroup mobileheightzero"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              opacity: "0",
              visibility: "hidden",
              margintop: "-55px",
            }}
          >
            <IconButton
              className="homeGalleryButton"
              onClick={() => {
                handleChange("minimal", [0.5, 1, 1]);
              }}
            >
              <img src="/images/grid4.png" style={minimalStyle} className="iconbuttonDG" />
            </IconButton>{" "}
            <IconButton
              style={{ zIndex: "9999" }}
              onClick={() => {
                handleChange("standard", [1, 0.5, 1]);
              }}
            >
              <img src="/images/grid9.png" style={standardStyle} className="iconbuttonDG" />
            </IconButton>{" "}
            <IconButton
              style={{ zIndex: "9999" }}
              onClick={() => {
                handleChange("single", [1, 1, 0.5]);
              }}
            >
              <img style={singleStyle} src="/images/grid1.png" className="iconbuttonDG" />
            </IconButton>{" "}
          </div>
        );
      default:
        return (
          <div
            className={
              tab == "single" ? "iconButtonGroup iconButtonGroupTopSpaceHandle" : "iconButtonGroup"
            }
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => {
                handleChange("minimal", [0.5, 1, 1]);
              }}
            >
              <img src="/images/grid4.png" style={minimalStyle} className="iconbuttonDG" />
            </IconButton>{" "}
            <IconButton
              style={{ zIndex: "9999" }}
              onClick={() => {
                handleChange("standard", [1, 0.5, 1]);
              }}
            >
              <img src="/images/grid9.png" style={standardStyle} className="iconbuttonDG" />
            </IconButton>{" "}
            <IconButton
              style={{ zIndex: "9999" }}
              onClick={() => {
                handleChange("single", [1, 1, 0.5]);
              }}
            >
              <img style={singleStyle} src="/images/grid1.png" className="iconbuttonDG" />
            </IconButton>{" "}
          </div>
        );
    }
  };

  return (
    <div>
      {isLoading ? (
        <center>
          {/* <LinearProgress color="secondary" /> */}
        </center>
      ) : null}
      {!isLoading && folderName.length === 0 ? (
        <>
          <NoModule text="No photos at the moment" />
        </>
      ) : (
        <>
          {getValueIconname()}
          <div
            className={tab == "single" ? "" : "galleryLayoutScroll"}
            style={
              tab == "single"
                ? {
                    width: "100%",
                  }
                : {
                    padding: "0px 51px 0px 47px",
                  }
            }
          >
            {getGalleryLayout()}
          </div>
        </>
      )}
    </div>
  );
};

export default Gallery;
