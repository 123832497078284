/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Gallery from "components/Gallery";

import ModuleBase from "components/ModuleBase";
import axios from "axios";
import { baseUrl } from "api";
import { ModuleId } from "utils/getModuleId";
import { useViewer } from "viewer";
import { CircularProgress, LinearProgress } from "@mui/material";
import NoModule from "components/NoModule";

// eslint-disable-next-line react/prop-types
function PhotoGalleryEventAreas({ moduleType }) {
  const { viewer, sideBarRoutes } = useViewer();

  const url = window.location.href;
  const moduleName = moduleType === "floor-plans" ? "floor-plans" : "photo-gallery";

  const [isLoading, setIsLoading] = useState({});
  const [folderName, setfolderName] = useState({});
  const [files, setFiles] = useState([]);
  const [moduleId, setModuleId] = useState("");
  const [tab, setTab] = React.useState("minimal");

  useEffect(() => {
    setIsLoading(true);
    setfolderName([]);
    setFiles([]);

    axios
      .get(`${baseUrl}/${moduleName}-folders/front-end?viewerId=${ModuleId(sideBarRoutes)}`)
      .then(async (res) => {
        setIsLoading(false);
        const folderData = await Promise.all(
          res?.data
            ?.sort((a, b) => a.order - b.order)
            ?.map(async (item) => {
              return {
                type: item.name,
                caption: item.name.toUpperCase(),
                thumb: item.thumbnail,
                moduleId: item.id,
                files: [],
              };
            })
        );

        setfolderName(folderData);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [viewer, moduleType, url, sideBarRoutes]);

  const [viewer_, setViewer] = useState({});

  useEffect(() => {
    setIsLoading(true);
    if (ModuleId(sideBarRoutes).length > 0) {
      axios
        .get(`${baseUrl}/viewer-modules/${ModuleId(sideBarRoutes)}?`)
        .then((res) => {
          setIsLoading(false);
          setViewer(res.data);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [sideBarRoutes, url]);

  console.log("---->>?-", viewer_);

  return (
    <>
      {/* {isLoading && <center>{<CircularProgress color="secondary" />}</center>} */}

      <ModuleBase
        component={
          isLoading ? (
            <div className="h-full w-full rounded-xl sm:overflow-hidden flex flex-col 2xl:justify-center items-center space-y-5 2xl:space-y-10 p-5 lg:p-10 overflow-y-scroll comingsoontext">
              <>
                <div className="text-center">
                  <CircularProgress
                    style={{
                      color: viewer?.windowColor ? `#${viewer?.windowColor}` : "white",
                    }}
                  />
                </div>
              </>
            </div>
          ) : !isLoading && folderName.length === 0 ? (
            <>
              <NoModule text="No photos at the moment" />
            </>
          ) : (
            <Gallery
              setTab={setTab}
              setFiles={setFiles}
              setModuleId={setModuleId}
              moduleType={moduleType}
              setIsLoading={setIsLoading}
              moduleId={moduleId}
              files={files}
              tab={tab}
              folderName={folderName}
              isLoading={isLoading}
              viewer_={viewer_}
            />
          )
        }
      />
    </>
  );
}
export default PhotoGalleryEventAreas;
