/* eslint-disable no-unused-vars */
import React from "react";

import VirtualTour from "layouts/pages/virtualtour";
import InstagramPage from "layouts/pages/instagram";
import MapDirections from "layouts/pages/map-directions";
import StagingOptions from "layouts/pages/staging-options";
import SalesDeck from "layouts/pages/sales-deck";
import EventPage from "layouts/pages/event_enquery";
import Custom from "layouts/pages/custom";

// @mui icons
import Modelling from "layouts/pages/modelling";
import Website from "layouts/pages/website";
import PhotoGalleryEventAreas from "layouts/pages/photo_gallery/event_areas";
import Videogallery from "layouts/pages/videogallery/index";
import FloorPlans from "layouts/pages/floor_plans";
import Default from "layouts/pages/default";

import {
  ArrowCycle,
  Image,
  Video,
  Globe,
  ShippingBoxV1,
  Calendar,
  InstagramFill,
  Location,
  Miniplayer,
  File,
  Info,
} from "akar-icons";
import Overview from "layouts/pages/overview";
// import { ArrowCycle, Image, Video, Globe, ShippingBoxV1, Paper, Calendar, InstagramFill, Location } from 'akar-icons';

// import Event from "layouts/pages/event_enquery";
let size = 18;

function routePage(mainRoute) {
  return `${mainRoute}`;
}

function keyPage(mainKey) {
  return `${mainKey}`;
}

export const availableModules = [
  "photo-gallery",
  "overview",
  "virtual-tour",
  "floor-plans",
  "video-gallery",
  "photo-gallery",
  "virtual-staging",
  "sales-deck",
  "contact-form",
  "custom",
  "directions",
  "instagram",
  "3d-model",
  "website",
];

const routes = [
  {
    type: "collapse",
    name: "DEFAULT",
    key: keyPage("default"),
    noCollapse: true,
    route: routePage("/default/:page"),
    icon: <ArrowCycle className="iconPositionChangeSN" strokeWidth={2} size={size} />,
    component: <Default />,
    id: 0,
  },
  {
    type: "collapse",
    name: "VIRTUAL TOUR",
    key: keyPage("virtualtour"),
    noCollapse: true,
    route: routePage("/virtual-tour"),
    icon: <ArrowCycle className="iconPositionChangeSN" strokeWidth={2} size={size} />,
    component: <VirtualTour />,
    id: 1,
  },
  {
    type: "collapse",
    name: "VIRTUAL TOUR",
    key: keyPage("virtualtour"),
    noCollapse: true,
    route: routePage("/virtual-tour/:name"),
    icon: <ArrowCycle className="iconPositionChangeSN" strokeWidth={2} size={size} />,
    component: <VirtualTour />,
    id: 1,
  },
  {
    type: "collapse",
    name: "PHOTO GALLERY",
    key: keyPage("photo-gallery"),
    noCollapse: true,
    route: routePage("/photo-gallery"),
    isReload: true,
    icon: <Image className="iconPositionChangeSN" size={size} color="#fff" />,
    component: <PhotoGalleryEventAreas />,
    id: 2,
  },
  {
    type: "collapse",
    name: "PHOTO GALLERY",
    key: keyPage("photo-gallery-name"),
    noCollapse: true,
    route: routePage("/photo-gallery/:name"),
    isReload: true,
    icon: <Image className="iconPositionChangeSN" size={size} color="#fff" />,
    component: <PhotoGalleryEventAreas />,
    id: 2,
  },
  {
    type: "collapse",
    name: "VIDEO GALLERY",
    key: keyPage("video-gallery"),
    noCollapse: true,
    route: routePage("/video-gallery"),
    isReload: true,
    icon: <Video strokeWidth={2} className="iconPositionChangeSN" size={size} />,
    component: <Videogallery />,
    id: 3,
  },
  {
    type: "collapse",
    name: "VIDEO GALLERY",
    key: keyPage("video-gallery"),
    noCollapse: true,
    route: routePage("/video-gallery/:name"),
    isReload: true,
    icon: <Video strokeWidth={2} className="iconPositionChangeSN" size={size} />,
    component: <Videogallery />,
    id: 3,
  },
  {
    type: "collapse",
    name: "FLOOR PLANS",
    key: keyPage("floor-plans"),
    // noCollapse: true,
    route: routePage("/floor-plans"),
    icon: <Miniplayer strokeWidth={2} className="iconPositionChangeSN" size={size} />,
    component: <PhotoGalleryEventAreas moduleType="floor-plans" />,
    id: 10,
  },
  {
    type: "collapse",
    name: "FLOOR PLANS",
    key: keyPage("floor-plans"),
    // noCollapse: true,
    route: routePage("/floor-plans/:name"),
    icon: <Miniplayer strokeWidth={2} className="iconPositionChangeSN" size={size} />,
    component: <PhotoGalleryEventAreas moduleType="floor-plans" subMenu="home" />,
    id: 10,
  },
  {
    type: "collapse",
    name: "3D MODEL",
    key: keyPage("3D-model"),
    noCollapse: true,
    route: routePage("/3D-model"),
    icon: <Globe strokeWidth={2} className="iconPositionChangeSN" size={size} />,
    component: <Modelling />,
    id: 4,
  },
  {
    type: "collapse",
    name: "3D MODEL",
    key: keyPage("3D-model-name"),
    noCollapse: true,
    route: routePage("/3D-model/:name"),
    icon: <Globe strokeWidth={2} className="iconPositionChangeSN" size={size} />,
    component: <Modelling />,
    id: 4,
  },
  {
    type: "collapse",
    name: "WEBSITE",
    key: keyPage("website"),
    noCollapse: true,
    route: routePage("/website"),
    icon: <Globe strokeWidth={2} className="iconPositionChangeSN" size={size} />,
    component: <Website />,
    id: 4,
  },
  {
    type: "collapse",
    name: "WEBSITE",
    key: keyPage("website"),
    noCollapse: true,
    route: routePage("/website/:name"),
    icon: <Globe strokeWidth={2} className="iconPositionChangeSN" size={size} />,
    component: <Website />,
    id: 4,
  },
  {
    type: "collapse",
    name: "STAGING OPTIONS",
    key: keyPage("virtual-staging"),
    noCollapse: true,
    route: routePage("/virtual-staging"),
    icon: <ShippingBoxV1 strokeWidth={2} className="iconPositionChangeSN" size={size} />,
    component: <StagingOptions />,
    id: 5,
  },

  {
    type: "collapse",
    name: "STAGING OPTIONS",
    key: keyPage("virtual-staging"),
    noCollapse: true,
    route: routePage("/virtual-staging/:name"),
    icon: <ShippingBoxV1 strokeWidth={2} className="iconPositionChangeSN" size={size} />,
    component: <StagingOptions />,
    id: 5,
  },

  {
    type: "collapse",
    name: "OVERVIEW",
    key: keyPage("overview"),
    noCollapse: true,
    route: routePage("/overview"),
    icon: <Info strokeWidth={2} className="iconPositionChangeSN" size={size} />,
    component: <Overview />,
    id: 100,
  },

  {
    type: "collapse",
    name: "OVERVIEW",
    key: keyPage("overview"),
    noCollapse: true,
    route: routePage("/overview/:name"),
    icon: <Info strokeWidth={2} className="iconPositionChangeSN" size={size} />,
    component: <Overview />,
    id: 100,
  },

  {
    type: "collapse",
    name: "SALES DECK",
    key: keyPage("sales-deck"),
    noCollapse: true,
    route: routePage("/sales-deck"),
    icon: <File strokeWidth={2} className="iconPositionChangeSN" size={size} />,
    component: <SalesDeck />,
    id: 6,
  },

  {
    type: "collapse",
    name: "SALES DECK",
    key: keyPage("sales-deck"),
    noCollapse: true,
    route: routePage("/sales-deck/:name"),
    icon: <File strokeWidth={2} className="iconPositionChangeSN" size={size} />,
    component: <SalesDeck />,
    id: 6,
  },

  {
    type: "collapse",
    name: "EVENT INQUIRY",
    key: keyPage("contact-form"),
    noCollapse: true,
    route: routePage("/contact-form"),
    icon: <Calendar strokeWidth={2} className="iconPositionChangeSN" size={size} />,
    component: <EventPage />,
    id: 7,
  },
  {
    type: "collapse",
    name: "EVENT INQUIRY",
    key: keyPage("contact-form"),
    noCollapse: true,
    route: routePage("/contact-form/:name"),
    icon: <Calendar strokeWidth={2} className="iconPositionChangeSN" size={size} />,
    component: <EventPage />,
    id: 7,
  },
  {
    type: "collapse",
    name: "INSTAGRAM",
    key: keyPage("instagram"),
    noCollapse: true,
    route: routePage("/instagram"),
    icon: <InstagramFill strokeWidth={2} className="iconPositionChangeSN" size={size} />,
    component: <InstagramPage />,
    id: 8,
  },
  {
    type: "collapse",
    name: "INSTAGRAM",
    key: keyPage("instagram"),
    noCollapse: true,
    route: routePage("/instagram/:name"),
    icon: <InstagramFill strokeWidth={2} className="iconPositionChangeSN" size={size} />,
    component: <InstagramPage />,
    id: 8,
  },
  {
    type: "collapse",
    name: "MAP / DIRECTIONS",
    key: keyPage("directions"),
    noCollapse: true,
    route: routePage("/directions"),
    icon: <Location strokeWidth={2} className="iconPositionChangeSN" size={size} />,
    component: <MapDirections />,
    id: 9,
  },
  {
    type: "collapse",
    name: "MAP / DIRECTIONS",
    key: keyPage("directions"),
    noCollapse: true,
    route: routePage("/directions/:name"),
    icon: <Location strokeWidth={2} className="iconPositionChangeSN" size={size} />,
    component: <MapDirections />,
    id: 9,
  },
  {
    type: "collapse",
    name: "CUSTOM",
    key: keyPage("custom"),
    noCollapse: true,
    route: routePage("/custom"),
    icon: <Location strokeWidth={2} className="iconPositionChangeSN" size={size} />,
    component: <Custom />,
    id: 11,
  },
  {
    type: "collapse",
    name: "CUSTOM",
    key: keyPage("custom"),
    noCollapse: true,
    route: routePage("/custom/:name"),
    icon: <Location strokeWidth={2} className="iconPositionChangeSN" size={size} />,
    component: <Custom />,
    id: 11,
  },
  {
    type: "imgWithInfo",
    name: "Basic",
    key: "basic",
  },

  {
    type: "core_Link",
    name: "BasicTwo",
    key: "basicTwo",
    collapse: [
      // Photo Gallery
      {
        name: "",
        key: "event-spaces",
        route: routePage("/photo-gallery/eventSpaces"),
        component: <PhotoGalleryEventAreas />,
      },
      {
        name: "",
        key: "Lobby",
        route: routePage("/photo-gallery/Lobby"),
        component: <PhotoGalleryEventAreas />,
      },
      {
        name: "",
        key: "Exterior",
        route: routePage("/photo-gallery/Exterior"),
        component: <PhotoGalleryEventAreas />,
      },
      {
        name: "",
        key: "Restaurant",
        route: routePage("/photo-gallery/Restaurant"),
        component: <PhotoGalleryEventAreas />,
      },
      {
        name: "",
        key: "Rooms",
        route: routePage("/photo-gallery/Rooms"),
        component: <PhotoGalleryEventAreas />,
      },
      {
        name: "",
        key: "Amenities",
        route: routePage("/photo-gallery/Amenities"),
        component: <PhotoGalleryEventAreas />,
      },
    ],
  },
];

export default routes;
